@import 'src/theme/core';
$style: BlockWrapper;
.#{$style} {
  background: $LIGHT_NEUTRALS_0;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 4px;
  overflow: hidden;
  &._small {
    min-height: initial;
    border-radius: 8px;
  }
  &._full {
    height: 100%;
  }
  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    margin-bottom: 0;
  }
  &__header {
    margin-bottom: 12px;
  }
  &__label {
    @include text($H12, 400, $LIGHT_NEUTRALS_6);
  }
  &__loader {
    position: relative;
    top: -9px;
    height: 22px;
  }
}
