@import 'src/theme/core';
$style: ProjectOriginSecurityView;

.#{$style} {
  &__additionalRateLimiting {
    padding-top: 0px;
    overflow: hidden;
    height: auto;
    transition: all 0.3s;

    &._disabled {
      overflow: hidden;
      height: 0px;
      min-height: 0px;
      opacity: 0;
      // padding: 0px !important;
      margin: 0px;
      transition: all 0.3s;
    }
  }

  &__additionalBotManagement {
    padding-top: 0px;
    overflow: hidden;
    height: auto;
    transition: all 0.3s;

    &._disabled {
      overflow: hidden;
      height: 0px;
      min-height: 0px;
      opacity: 0;
      // padding: 0px !important;
      margin: 0px;
      transition: all 0.3s;
    }
  }

  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    margin: 0;
  }

  &__head {
    padding: 12px 24px 0px;
  }

  &__table-head {
    display: flex;
    align-items: center;
    padding: 12px 24px;
  }

  &__table-row {
    display: flex;
    align-items: center;
    border: 1px solid $LIGHT_NEUTRALS_3;
    // border-left: 1px solid $LIGHT_NEUTRALS_3;
    // border-right: 1px solid $LIGHT_NEUTRALS_3;
    padding: 19px 16px;
  }

  &__head-col {
    display: flex;
    flex-direction: row;
    align-items: center;

    &._ta-right {
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__setting-icon {
    color: $LIGHT_NEUTRALS_6;
    font-size: 20px;
    transition: all 0.2s ease;

    &:hover {
      color: $LIGHT_BLUE_6;
      transition: all 0.2s ease;

    }
  }


  &__slider {
    margin: 16px 6px 30px;

    .ant-slider-disabled .ant-slider-dot {
      border: none !important;
    }

    &::after {
      display: none;
    }

    .ant-slider-disabled {
      &::after {
        display: none;
      }
    }

    .ant-slider-handle {
      width: 12px;
      height: 12px;

      &::after {
        display: none;
      }
    }

    .ant-slider-with-marks {
      margin: 16px 6px 30px !important;
      // border: solid 2px yellow !important;
    }


  }

  &__head-col {
    &._ta-left {
      text-align: left;
    }
  }

  &__hostname-column {
    position: relative;
    bottom: 0px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    cursor: default;
  }

  &__head-label {
    @include text($H10, 600, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
  }

  &__table-item {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 14px 24px;
  }

  &__table-col {
    &._ta-left {
      text-align: left;
    }
  }

  &__table-col {
    &._ta-right {
      text-align: right;
    }
  }

  &__table-label {
    @include text($H12, 400, $LIGHT_NEUTRALS_10);
  }

  &__badge {
    margin-left: 12px;
  }

  &__drawer {
    .ant-drawer-footer {
      border: none;
    }
  }

  .hostnameInput {
    width: 100%;
  }

  &__dnsLabel {
    border: 1px solid $LIGHT_NEUTRALS_6;
    border-radius: 25px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.2s ease;

    .dnsLabelInner {
      display: flex;
      flex-direction: row;
      width: fit-content;
      align-items: center;
      padding: 0px 10px;
      white-space: nowrap;
    }

    .dnsLabelIcon {
      color: $LIGHT_RED_ACCENT;
      padding-left: 5px;
      font-size: 20px;
      transform: rotate(180deg);
    }

    .dnsLabelCheckIcon {
      color: $LIGHT_GREEN_3;
      padding-right: 5px;
      font-size: 20px;
    }

    &:hover {
      background-color: $LIGHT_NEUTRALS_2;
      transition: all 0.2s ease;

    }

  }

  &__tlsLabel {
    border: 1px solid $LIGHT-GREEN_3;
    border-radius: 25px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.2s ease;

    .tlsLabelInner {
      display: flex;
      flex-direction: row;
      width: fit-content;
      align-items: center;
      padding: 0px 10px;
      white-space: nowrap;

      .tlsLabelStatus {
        display: block;
        position: relative;
        border-radius: 50%;
        background-color: $LIGHT-GREEN_3;
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    &:hover {
      background-color: $LIGHT_NEUTRALS_2;
      transition: all 0.2s ease;

    }
  }

  &__removeIcon {
    cursor: pointer;
    font-size: 18px;
    transition: all 0.2s ease;
    fill: $DARK_NEUTRALS_12;

    &:hover {
      transform: scale(1.06);
      transition: all 0.2s ease;
    }
  }

  &__arrowIcon {
    position: relative;
    top: 2px;
    margin-left: 5px;
    font-size: 18px;
    opacity: 1;
    transition: all 0.2s ease;

    svg {
      cursor: pointer;
      transition: all 0.2s ease;
      fill: $LIGHT_NEUTRALS_6;
      stroke: $LIGHT_NEUTRALS_6;
    }

    &:hover {
      svg {
        transform: scale(1.05);
        transition: all 0.2s ease;
        // fill: $LIGHT_NEUTRALS_6;
        // stroke: $LIGHT_NEUTRALS_6;
      }
    }
  }

  &__link-icon {
    color: $LIGHT_NEUTRALS_6;
    margin-left: 6px;
    @include transition(color);
    cursor: pointer;

    &:hover {
      color: $LIGHT_NEUTRALS_7;
    }

    &._margin-less {
      margin-left: 0;
    }
  }

  &__select {
    width: 100%;
  }
}



.title {
  @include text($H16, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 14px;
}

.switchText {
  @include text($H12, 400, $LIGHT_NEUTRALS_11);
}

.boldText {
  @include text($H12, 600, $LIGHT_NEUTRALS_11);
}

// .ant-collapse-header {
//   display: flex;
//   flex-direction: row-reverse;
// }

.ant-collapse {
  border-radius: 2px;
  width: 100%;
  transition: all 0.2s ease;

  &:hover {
    background-color: $LIGHT_NEUTRALS_2;
    transition: all 0.2s ease;

  }

  .ant-collapse-header {
    width: 100%;
    padding: 0 !important;
  }

  .ant-collapse-header-text {
    width: calc(100% - 75px);
    padding: 7px 0;
  }

  .ant-collapse-expand-icon {
    cursor: pointer;
    padding: 7px 16px;
  }
}

.tlsCollapseWrapper {
  display: flex;
  align-items: center;

  .tlsLabel {
    border: 1px solid $LIGHT-GREEN_3;
    border-radius: 25px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.2s ease;

    .tlsLabelInner {
      display: flex;
      flex-direction: row;
      width: fit-content;
      align-items: center;
      padding: 0px 10px;
      white-space: nowrap;

      .tlsLabelStatus {
        display: block;
        position: relative;
        border-radius: 50%;
        background-color: $LIGHT-GREEN_3;
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    &:hover {
      background-color: $LIGHT_NEUTRALS_2;
      transition: all 0.2s ease;

    }
  }


}

.copyIcon {
  cursor: pointer;
  font-size: 18px;
}

.Input {

  .Input__input._textarea,
  .Input .Input__input.ant-input-affix-wrapper._textarea {
    height: 120px;
  }
}

.inputCertif {
  // width: 70%;

  textarea {
    font-size: 12px !important;
    font-family: Menlo, Monaco, Courier New, monospace;
    word-break: break-all;
  }

  textarea::placeholder {
    font-size: 11px !important;
    font-family: Menlo, Monaco, Courier New, monospace;
    word-break: break-all;
  }

  textarea::spelling-error {
    text-decoration: none;
  }
}

._arrowIconDisabled {
  pointer-events: none;
  transition: all 0.2s ease;
  opacity: 0.4;
}

.tlsCollapseLoader {
  position: absolute;
  left: 190px;
  top: -1px;
}

.coppyIcon {
  color: $LIGHT_NEUTRALS_6;
  transition: all 0.2s ease;

  &:hover {
    color: $LIGHT_NEUTRALS_8;
  }
}

.switchWrapper {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1px 8px;
  background-color: #F5F6F8;
  border-radius: 7px;
  left: 8px;
  position: relative;

  .switchRedCirle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: $LIGHT_RED_ACCENT;
  }

  .switchGreenCirle {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: $LIGHT-GREEN_3;
  }

  .switchText {
    font-size: 12px;
  }
}

.drawerContentWrapper {
  padding: 0px 32px;
  margin-top: 0px;

  .drawerContentRow {
    margin-bottom: 30px;
    padding: 0px;
  }

  .defaultMargin {
    margin: 5px 0px;
  }
}
