@import 'src/theme/core';
$style: HomeAddModalNew;

.#{$style} {
  &__footer {
    @include flex(space-between, center);
    gap: 16px;
    padding: 16px 0 0;

    &._last {
      justify-content: flex-end;
    }
  }

  &__right-block {
    @include flex(center, center);
    background: #f8faff;
    margin: -24px 0;
    right: -24px;
    border-radius: 0 8px 8px 0;

    &>* {
      width: 100%;
    }
  }

  &__contactInput {
    margin-bottom: 10px;
  }

  &__column {
    @include flex(space-between, stretch, column);
  }

  &__title {
    @include text($H20, 600, $LIGHT_NEUTRALS_11);
  }

  &__subtitle {
    // position: relative;
    // bottom: 12px;
    font-size: 0.75rem;
    font-weight: 400;
  }

  &__second-image {
    position: relative;
    left: -22px;
  }

  &__content {
    &._small {
      max-width: 360px;
    }
  }

  &__progress-wrapper {
    @include flex(center, center, column);
    height: 100%;
  }

  &__subtext {
    @include text($H14, 400, $LIGHT_NEUTRALS_7);
    margin-bottom: 24px;
  }

  &__card {
    width: 100%;
    height: 100%;
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 4px;

    &._active {
      background-color: $LIGHT_BLUE_1;
    }



    &-head {
      border-bottom: 1px solid $LIGHT_NEUTRALS_3;
      padding: 16px;

      ._enterprise {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      ._enterpriseName {
        margin-left: 0px !important;
      }

      ._subtextEnterprise {
        padding-left: 0px !important;
        position: relative;
        cursor: pointer;
        right: 11px;
        color: $LIGHT_BLUE_3_ORIGINAL !important;

        transition: color 0.2s ease;

        &:hover {
          scale: (1.01);
          color: $LIGHT_BLUE_8 !important;
        }
      }
    }

    &-name {
      @include text($H16, 600, $LIGHT_NEUTRALS_7);
      margin-left: 10px;
    }

    &-subtext {
      @include text($H10, 400, $LIGHT_NEUTRALS_9);
      display: inline-block;
      padding-left: 28px;
      white-space: break-spaces;
    }

    &-price {
      @include text($H16, 600, $LIGHT_BLUE_6);
    }

    &-priceCrossedOut {
      position: absolute;
      bottom: 17px;
      left: 0px;
      text-decoration: line-through;
      opacity: .7;
    }

    &-col {
      @include flex(flex-start, center);
    }

    &-subtitle {
      @include text($H12, 400, $LIGHT_NEUTRALS_11);
      display: inline-block;
      margin-bottom: 10px;
      white-space: break-spaces;
    }

    &-content {
      padding: 16px;
    }

    &-check {
      color: $LIGHT-GREEN_3;
    }

    &-value {
      white-space: break-spaces;
      @include text($H12, 400, $LIGHT_NEUTRALS_9);
      line-height: 1;
    }

    &-tooltip {
      @include text($H10, 400, $LIGHT_NEUTRALS_7);
    }

    &-question {
      color: $LIGHT_NEUTRALS_7;
    }
  }

  & .anticon.anticon-check {
    color: $LIGHT-GREEN_3;
  }

  & .ant-progress-text {
    @include text($H16, 400, $LIGHT_NEUTRALS_7);
  }

  &__project-button {
    @include flex(center, center);
    background: $LIGHT_NEUTRALS_0;
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 4px;
    padding: 16px;
    height: 56px;
    width: 100%;
    cursor: pointer;
    @include transition(border-color, background-color);
    position: relative;

    &._active {
      border-color: $LIGHT_BLUE_2;
      background-color: $LIGHT_BLUE_1;

      .#{$style}__project-check {
        display: block;
      }
    }
  }

  &__project-icon {
    height: 100%;
  }

  &__project-tooltip {
    height: 100%;
  }

  &__project-tooltip-title {
    @include text($H14, 600, $LIGHT_NEUTRALS_9);
    margin-bottom: 6px;
  }

  &__project-tooltip-text {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
  }

  &__alert {
    &.ant-alert-error {
      border-radius: 6px;
      margin-bottom: 16px;
      background-color: $LIGHT_RED_1;
      border-color: $LIGHT_RED_ACCENT;
    }

    & .ant-alert-message {
      @include text($H12, 400, $LIGHT_RED_ACCENT);
    }
  }

  &__project-check {
    color: $LIGHT_BLUE_5;
    position: absolute;
    top: 8px;
    right: 8px;
    display: none;
  }
}

.dragged {
  z-index: 20;
}

.ProjectOriginOrigin {
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 0.4s ease;
  max-height: 78vh;
}

.createPropertyLabel {
  font-size: 10px;
  font-weight: 400;
  color: '#192a3e'
}

.createPropertySubtitle {
  position: relative;
  bottom: 2px;
  font-size: 12px;
  font-weight: 400;
}

.createPropertyMarginTop20 {
  margin-top: 20px;
}

.createPropertyMarginTop10 {
  margin-top: 10px;
}

.createPropertyMarginTop5 {
  margin-top: 5px;
}

.createPropertyMapHostnamesTitle {
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.createPropertyMapHostnamesLabel {
  font-size: 14px;
  color: grey;
}

.createPropertyBtnBlock {
  display: flex;
  justify-content: space-between;
  // flex-direction: row-reverse;
  padding: 0px 35px;
}

.createPropertyDeleteIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.originRowHostnames {
  margin-top: 10px;
  padding: 0 !important;
}

.OriginHostsLable {
  font-size: 0.625rem;
  font-weight: 400;
  color: #192a3e;
}

.originRowHostnamesRow {
  padding: 0;
  margin: 0;
}

.OriginHostsLableOriginHostname {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.OriginHostsLableOriginHostname-required {
  font-size: 0.625rem;
  font-weight: 400;
  color: #90a0b7;
  margin-left: 8px;
}

._blur {
  cursor: default;
  filter: blur(1px);
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  pointer-events: none;
  transition: all 0.4s ease;
}

.loaderPopr {
  position: absolute;
  width: 100%;
  height: 75%;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50px;
  left: 0;
}

._requiredField {
  .Input__input {
    border: 1px solid $LIGHT_RED_ACCENT;
  }

  .Select__select {
    border: 1px solid $LIGHT_RED_ACCENT;
  }
}

._invalidURL {
  .Input__input {
    border: 1px solid $LIGHT_RED_ACCENT;
  }

  ._focused {
    border-color: $LIGHT_RED_ACCENT !important;
  }
}

._isFirst {
  .Input__input {
    border-left: 5px solid $LIGHT_BLUE_BORDER;
  }
}

.draggableIcon {
  position: relative;
  top: 1px;
  padding: 5px 0px;
  z-index: 111;
  transition: all 0.1s ease-in-out;

  svg {
    cursor: grabbing;
  }

  &:hover {
    transform: scale(1.08);
    color: $LIGHT_BLUE_BORDER;
    cursor: grab;
  }
}

.shieldsDivider {
  margin: 6px 0;
}
