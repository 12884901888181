@import 'src/theme/core';

$style: bannerWrapper;

.#{$style} {
  position: sticky;
  z-index: 101;
  width: 100%;
  padding-bottom: 10px;

  .bannerInner {
    position: relative;
    background-color: $LIGHT_BLUE_6;
    justify-content: space-between;
    padding: 5px 10px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
  }

  .bannerInner-deploySection {
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;

    .bannerInner-deploySection-title {
      margin: 0px 20px;
      font-size: 15;
      font-weight: 600
    }

    .bannerInner-deploySection-button .Button__text {
      font-size: 14px;
      font-weight: 600
    }
  }

  .bannerInner-actionsSection {
    .bannerInner-actionsSection-button .Button__text {
      font-size: 14px;
    }

    .bannerInner-actionsSection-divider {
      width: 1px;
      height: 30px;
      background-color: #fff;
      margin: 0px 10px;
    }
  }
}
