@import 'src/theme/core';
$style: UserSettingsInvoices;

.#{$style} {
  &__wrapper {
    // margin-top: 20px;
  }

  &__title {
    @include text($H18, 600, $LIGHT_NEUTRALS_11);
    margin-bottom: 4px;
  }

  &__subtitle {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    margin-bottom: 24px;
    display: inline-block;
  }

  &__table-head-item {
    @include flex(flex-end);

    &._start {
      @include flex(flex-start);
      padding-left: 12px;
    }
  }

  &__table-head {
    // border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding-bottom: 12px;
  }

  &__filter {
    background-color: transparent;
    border: none;
    outline: none;
    @include text($H10, 500, $LIGHT_NEUTRALS_6);
    @include flex(center, flex-end);
    transition: all 0.2 ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__arrow {
    font-size: 14px;
    margin-left: 4px;
    position: relative;
    bottom: 1px;
  }

  &__col {
    @include flex(flex-end, center);
    border-top: 1px solid $LIGHT_NEUTRALS_3;
    // border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding-bottom: 14px;

    &._primary {
      padding-bottom: 7px;
      border-bottom-color: transparent;
    }

    &._last {
      border-bottom-color: transparent;
    }

    &._first {
      border-top-color: transparent;
    }
  }

  &__table-item {
    padding: 0 14px 0 0;

    &._primary {
      background-color: $LIGHT_NEUTRALS_1;
      margin-bottom: 4px;
    }

    &._first {
      border-top-color: transparent;
    }
  }

  &__table-item-first {
    padding-bottom: 14px;
    padding-top: 12px;
    cursor: pointer;
    border-top: 1px solid $LIGHT_NEUTRALS_3;
    @include flex(flex-start, center);

    &._primary {
      padding-left: 20px;
      padding-bottom: 7px;
      padding-top: 7px;
    }

    &._first {
      border-top-color: transparent;
    }

    &._last {
      border-bottom-color: transparent;
    }
  }

  &__upload-icon {
    font-size: 16px;
  }

  &__expand-icon {
    font-size: 22px;
    color: $LIGHT_NEUTRALS_6;
    margin-right: 16px;
    transform: rotate(-90deg);

    &._active {
      transform: rotate(0deg);
    }
  }

  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_10);
  }

  &__currency {
    position: relative;
    color: $LIGHT_NEUTRALS_7;
    top: 2px;
  }

  &__empty-state {
    height: calc(100vh - 200px);
  }

  &__empty-state-title {
    @include text($H32, 600, $LIGHT_NEUTRALS_11);
  }

  &__empty-state-ilustration {
    height: 100%;
    width: 100%;
  }
}
