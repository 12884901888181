@import 'src/theme/core';
.button {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  margin: 0;
}
.name {
  @include text($H14, 500, $LIGHT_NEUTRALS_10);
  margin: 0;
}
.icon {
  @include text($H18, 400, $LIGHT_RED_ACCENT);
  &.orange {
    color: $LIGHT_ORANGE_ACCENT;
  }
  &.red {
    color: $LIGHT_RED_ACCENT;
  }
  &.green {
    color: $LIGHT-GREEN_3;
  }
  &.blue {
    color: $LIGHT_BLUE_3_ORIGINAL;
  }
  &.yellow {
    color: $LIGHT_YELLOW_1;
  }
}
.item {
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  &.active {
    background-color: $LIGHT_NEUTRALS_3;
  }
}
.dropdown {
  position: absolute;
  right: 40px;
  top: 68px;
}
.ruleName {
  @include text($H16, 500, $LIGHT_NEUTRALS_11);
  margin-bottom: 8px;
}
.ruleValue {
  @include text($H14, 400, $LIGHT_NEUTRALS_7);
}
.col {
  @include flex(flex-start, flex-start, column);
}
.ruleTitle {
  @include text($H16, 600, $LIGHT_NEUTRALS_11);
}
.nameIcon {
  margin-right: 6px;
}
.emptyState {
  position: fixed;
  top: 104px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f6f8;
}
.emptyTitle {
  @include text($H32, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 20px;
}
.emptyText {
  @include text($H16, 400, $LIGHT_NEUTRALS_7);
  margin-bottom: 36px;
}
.imageBlock {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    background: radial-gradient(
        58.66% 51.3% at 48.74% 51.37%,
        rgba(255, 199, 129, 0.7) 13.54%,
        rgba(255, 199, 129, 0.5) 39.58%,
        rgba(255, 199, 129, 0.3) 66.87%,
        rgba(255, 199, 129, 0) 87.5%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    opacity: 0.6;
    filter: blur(40px);
    left: -6%;
    top: -30%;
    z-index: 0;
  }
  &:after {
    content: '';
    position: absolute;
    width: 520px;
    height: 520px;
    background: radial-gradient(
        51.16% 52.01% at 49.02% 48.46%,
        #387bff 0%,
        rgba(56, 123, 255, 0.49) 35.94%,
        rgba(56, 123, 255, 0.12) 66.87%,
        rgba(56, 123, 255, 0) 89.92%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    opacity: 0.6;
    filter: blur(4px);
    right: -1%;
    bottom: -30%;
  }
}
.ilustration {
  position: relative;
  z-index: 1;
}
