@import '../../../../theme/core';
.empty {
  width: 100%;
  position: relative;
}
.form {
  background-color: $LIGHT_NEUTRALS_0;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 8px;
  padding: 32px;
}
.rightBlock {
  @include flex(center, center);
}
.input {
  background-color: $LIGHT_NEUTRALS_1;
  border: 1px solid $LIGHT_NEUTRALS_4;
  border-radius: 4px;
  height: 32px;
  padding: 8px 16px;
  @include transition(border-color, box-shadow);
  &.focus {
    box-shadow: 0px 0px 1px 2px rgba(26, 89, 217, 0.1);
    border-color: $LIGHT_BLUE_6;
  }
  &.invalid {
    border-color: $LIGHT_RED_ACCENT;
  }
}
.label {
  flex: 1;
  width: 100%;
}
.inputName {
  @include text($H10, 400, $LIGHT_NEUTRALS_11);
  margin-bottom: 7px;
  display: block;
}

.cardTitle {
  @include text($H16, 600, $LIGHT_NEUTRALS_10);
  margin-bottom: 22px;
}
.image {
  @include flex(center, center);
  position: relative;
}
.stripe {
  position: absolute;
  left: 0;
  bottom: 0;
}
