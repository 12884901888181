@import 'src/theme/core';
$style: ProjectHead;

.#{$style} {
  padding: 0px 16px;

  &__title {
    position: relative;
    bottom: 3px;
    line-height: 1;
  }

  &__pageName {
    @include text($H18, 600, $LIGHT_NEUTRALS_10);
    padding-left: 16px;
    line-height: 32px;
    min-height: 32px;
    position: relative;
    bottom: 4px;
  }

  &__trigger-url {
    h1 {
      @include flex(flex-start, center);
    }

    cursor: pointer;
  }

  &__arrow-icon {
    position: relative;
    top: 3px;
    margin-left: 12px;
    font-size: 28px;
    @include transition(transform);
  }

  &__small-arrow {
    margin-left: 10px;
    position: relative;
    top: 1px;
    font-size: 14px;
    @include transition(transform);
  }

  &__purge {
    margin-top: 16px;

    &._with-mb {
      margin-bottom: 16px;
    }
  }

  &__sector-item {
    background-color: transparent;
    border: none;
    outline: none;
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    cursor: pointer;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $LIGHT_BLUE_6;
    }
  }

  &__sector-dropdown {
    @include flex(flex-start, flex-start, column);
  }

  &__purge-text {
    color: $LIGHT_BLUE_6;
    @include flex(center, center);
    position: relative;
    top: 2px;

    &._blue {
      color: $LIGHT_BLUE_6;
    }
  }

  &__status {
    margin-right: 6px;
  }

  &__router-link {
    @include text($H18, 400, $LIGHT_NEUTRALS_7);

    &._active {
      font-weight: 600;
      color: $LIGHT_NEUTRALS_11;
    }
  }

  &__engine-arrow {
    margin-left: 6px;
    position: relative;
    top: 3px;
    font-size: 18px;
    @include transition(transform);
  }

  &__dropdown-btn {
    background-color: transparent;
    border: none;
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    cursor: pointer;
    @include flex(space-between, center);
    width: 100%;

    &._active {
      color: $LIGHT_BLUE_6;

      .#{$style}__dropdown-check {
        display: inline-flex;
      }
    }

    &:disabled {
      color: $DARK_NEUTRALS_4;
      cursor: not-allowed;
    }
  }

  &__dropdown-check {
    display: none;

    &._green {
      display: initial;
      padding-left: 4px;
    }
  }

  &__label {
    margin-right: 8px;
  }

  &__collapsed {
    position: relative;
    top: 3px;
    @include text($H20, 400, $LIGHT_NEUTRALS_7);
    @include transition(color);
    cursor: pointer;

    &:hover {
      color: $LIGHT_NEUTRALS_11;
    }
  }

  &__route {
    @include text($H14, 400, $LIGHT_NEUTRALS_6);
    cursor: pointer;
  }

  &__popconfirm {
    p {
      padding: 0;
      margin: 0;
      line-height: 15px;
    }
  }

  &__dropdown-icon {
    color: $LIGHT_BLUE_6;
    font-size: 16px;
    @include transition(transform);

    &._active {
      transform: rotate(180deg);
    }
  }

  &__calendar {
    background: red;

    & .ant-picker-input {
      &>input {
        @include text($H14, 600, $LIGHT_BLUE_6);
        padding: 0;
        width: 66px;
      }
    }

    & .ant-picker-clear {
      display: none !important;
    }
  }

  &__separator {
    display: inline-block;
    width: 12px;
    height: 1px;
    background-color: $LIGHT_BLUE_6;
    @include flex(center, center);
  }

  &__calendar-dropdown {
    & .ant-picker-panel-container {
      @include flex(stretch, stretch);
    }

    & .ant-picker-footer {
      border-left: 1px solid $LIGHT_NEUTRALS_3;
      border-bottom: none;
      // position: absolute;
      // left: -100px;
      // top: 8px;
      // bottom: 8px;
      // width: 100px;
      min-width: 180px;
      // background-color: $LIGHT_NEUTRALS_0;
      // box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      //   0 9px 28px 8px rgb(0 0 0 / 5%);
      //   border-radius: 2px 0 0 2px;
    }
  }

  &__extraFooter {
    @include flex(stretch, stretch, column);
    gap: 8px;
    padding: 16px 4px;
  }

  &__live-button {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: #fff !important;
    pointer-events: none;
    padding-left: 180px;
    cursor: pointer;
  }

  &__propagation {
    @include text($H14, 400, $LIGHT_NEUTRALS_6);

    &._realtime {
      position: absolute;
      right: -120px;
      z-index: 20;
      top: -10px;
    }
  }

  &__percents {
    &._green {
      color: $LIGHT-GREEN_3;
    }

    &._orange {
      color: $LIGHT_ORANGE_ACCENT;
    }

    &._red {
      color: $LIGHT_RED_ACCENT;
    }
  }
}
