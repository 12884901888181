@import 'src/theme/core';

.drawer {
  padding: 0 0 88px;
  outline: none;
}

.header {
  padding: 16px 24px;
}
.headerCol {
  @include flex(flex-start, flex-start, column);
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
}
.title {
  @include text($H14, 500, $LIGHT_NEUTRALS_11);
  margin-bottom: 4px;
  &.block {
    margin-bottom: 12px;
  }
}
.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_6);
  @include flex(flex-start, center);
  gap: 4px;
}
.icon {
  font-size: $H16;
}
.tags {
  padding: 4px 24px 0px;
}
.body {
  padding: 0 24px;
}
.requestRow {
  margin-bottom: 26px;
}
.request {
  @include flex(flex-start, center);
}
.type,
.query,
.requestName {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  @include flex(flex-start, center);
  height: 22px;
  padding: 0 8px;
  background: $LIGHT_NEUTRALS_3;
}
.type {
  border-radius: 4px 0 0 4px;
}
.query {
  border-radius: 0 4px 4px 0;
}
.requestName {
  padding: 0 22px 0 8px;
  background: $LIGHT_NEUTRALS_1;
}
.footer {
  width: 100%;
  padding: 20px 24px;
  border-top: 1px solid $LIGHT_NEUTRALS_4;
  position: absolute;
  bottom: 0;
  background: $LIGHT_NEUTRALS_0;
}
.footerText {
  @include text($H13, 400, $LIGHT_NEUTRALS_6);
}
.footerCol {
  @include flex(center, center);
}
