@import 'src/theme/core';
$style: ProjectOriginAlerts;
.tableHead {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  padding: 12px 24px;
}
.headCol {
  &.rightAlign {
    text-align: right;
  }
}
.headLabel {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}
.item {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  padding: 14px 24px;
  &.last {
    border-bottom: none;
  }
}
.col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.rightAlign {
    text-align: right;
  }
}
.tableLabel {
  @include text($H12, 400, $LIGHT_NEUTRALS_10);
}
.linkIcon {
  color: $LIGHT_NEUTRALS_6;
  margin-left: 6px;
  @include transition(color);
  cursor: pointer;
  &:hover {
    color: $LIGHT_NEUTRALS_7;
  }
  &.ml {
    margin-left: 0;
  }
}
.title {
  @include text($H16, 600, $LIGHT_NEUTRALS_7);
}
