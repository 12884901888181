@import 'src/theme/core';
$style: UserSettingsSettings;

.#{$style} {
  width: 100%;
  max-width: 654px;
  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_10);
    margin-bottom: 24px;
    &._main {
      font-size: $H18;
      margin-bottom: 4px;
    }
  }
  &__subtitle {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    margin-bottom: 0;
  }
  &__user-name {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    margin-bottom: 6px;
    line-height: 0.8;
    margin-top: 8px;
  }
  &__delete {
    margin-right: 8px;
    &>span {
      color: $DARK_NEUTRALS_7 !important;
    }
  }
  &__line {
    width: 14px;
    height: 1px;
    background: $LIGHT_NEUTRALS_11;
    position: absolute;
    left: 50%;
    bottom: 38px;
    transform: translateX(-50%);
  }
  &__upload {
    .ant-upload-list.ant-upload-list-text {
      display: none;
    }
  }
  &__btn {
    width: 106px !important;
  }
}
