@import 'src/theme/core';

.button {
  @include flex(center, center);
  border: none;
  background: $LIGHT_NEUTRALS_5;
  outline: none;
  cursor: pointer;
  @include transition(background-color);
  width: 10px;
  height: 10px;
  span {
    font-size: 12px;
    line-height: 0;
    position: relative;
    color: $LIGHT_NEUTRALS_0;
  }
  &:hover {
    background-color: $LIGHT_NEUTRALS_6;
  }

  &.expanded {
    span {
      bottom: 1px;
      font-size: 14px;
    }
  }
}
.curly {
  @include text($H14, 400, $LIGHT_NEUTRALS_5);
}
.table {
  width: 100%;
}
