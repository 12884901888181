@import 'src/theme/core';
.drawer {
  background-color: $LIGHT_NEUTRALS_1;
  height: calc(100vh - 98px);
  overflow: auto;
  padding: 18px 24px;
  @include flex(center, flex-start);
}
.table {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid $LIGHT_NEUTRALS_3;
  width: 100%;
}
.head {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
}
.item {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  &.withSub {
    cursor: pointer;
  }
  &.sub {
    background-color: $LIGHT_NEUTRALS_2;
  }
}
.col {
  padding: 8px 24px;
  position: relative;
}
.headName {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}
.name {
  @include text($H12, 400, $LIGHT_NEUTRALS_11);
}
.value {
  @include text($H12, 400, $LIGHT_BLUE_6);
}
.expand {
  font-size: 16px;
  color: $LIGHT_BLUE_3_ORIGINAL;
  position: absolute;
  left: 2px;
  top: 12px;
  &.active {
    transform: rotate(90deg);
  }
}
