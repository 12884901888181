@import 'src/theme/core';
$style: ProjectOriginCdnCnames;

.#{$style} {
  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    margin: 0;
  }

  &__head {
    padding: 12px 24px 0px;
  }

  &__table-row {
    display: flex;
    align-items: center;
    border: 1px solid $LIGHT_NEUTRALS_3;
    padding: 12px 16px;
  }

  &__table-head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 12px 16px;
  }

  &__head-col {
    display: flex;
    flex-direction: row;
    align-items: center;

    &._ta-right {
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__head-col {
    &._ta-left {
      text-align: left;
      padding: 0px 8px;
    }
  }

  &__head-label {
    @include text($H10, 600, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
  }

  &__table-item {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 14px 24px;
  }

  &__table-col {
    &._ta-left {
      text-align: left;
    }
  }

  &__table-col {
    &._ta-right {
      text-align: right;
    }
  }

  &__table-label {
    @include text($H12, 400, $LIGHT_NEUTRALS_10);
  }

  &__badge {
    margin-left: 12px;
  }

  .hostnameInput {
    width: 90%;
  }

  .Input__input {
    width: 90% !important;
  }

  &__dnsLabel {
    border: 1px solid $LIGHT_NEUTRALS_6;
    border-radius: 25px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.2s ease;

    .dnsLabelInner {
      display: flex;
      flex-direction: row;
      width: fit-content;
      align-items: center;
      padding: 0px 10px;
      white-space: nowrap;
    }

    .dnsLabelIcon {
      color: $LIGHT_RED_ACCENT;
      padding-left: 5px;
      font-size: 20px;
      transform: rotate(180deg);
    }

    .dnsLabelCheckIcon {
      color: $LIGHT_GREEN_3;
      padding-right: 5px;
      font-size: 20px;
    }

    &:hover {
      background-color: $LIGHT_NEUTRALS_2;
      transition: all 0.2s ease;

    }

  }

  &__tlsLabel {
    border: 1px solid $LIGHT-GREEN_3;
    border-radius: 25px;
    width: fit-content;
    cursor: pointer;
    transition: all 0.2s ease;

    .tlsLabelInner {
      display: flex;
      flex-direction: row;
      width: fit-content;
      align-items: center;
      padding: 0px 10px;
      white-space: nowrap;

      .tlsLabelStatus {
        display: block;
        position: relative;
        border-radius: 50%;
        background-color: $LIGHT-GREEN_3;
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    &:hover {
      background-color: $LIGHT_NEUTRALS_2;
      transition: all 0.2s ease;

    }
  }

  &__removeIcon {
    cursor: pointer;
    font-size: 18px;
    transition: all 0.2s ease;
    fill: $DARK_NEUTRALS_12;

    &:hover {
      transform: scale(1.06);
      transition: all 0.2s ease;
    }
  }

  &__removeModalIcon {
    cursor: pointer;
    font-size: 18px;
    transition: all 0.2s ease;
    fill: $DARK_NEUTRALS_12;
    position: relative;
    bottom: 3px;

    &:hover {
      transform: scale(1.06);
      transition: all 0.2s ease;
    }
  }

  &__arrowIcon {
    margin-left: 5px;
    font-size: 18px;

    svg {
      cursor: pointer;
      transition: all 0.2s ease;
      fill: $LIGHT_NEUTRALS_6;
      stroke: $LIGHT_NEUTRALS_6;
    }

    &:hover {
      svg {
        transform: scale(1.05);
        transition: all 0.2s ease;
        // fill: $LIGHT_NEUTRALS_6;
        // stroke: $LIGHT_NEUTRALS_6;
      }
    }

  }

  &__link-icon {
    color: $LIGHT_NEUTRALS_6;
    margin-left: 6px;
    @include transition(color);
    cursor: pointer;

    &:hover {
      color: $LIGHT_NEUTRALS_7;
    }

    &._margin-less {
      margin-left: 0;
    }
  }

  &__select {
    width: 100%;
  }
}
