@import 'src/theme/core';
$style: ProjectOriginOrigin;

.#{$style} {
  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_9);
    margin-bottom: 14px;
  }

  &__checkbox {
    @include flex(flex-start);
    padding-bottom: 4px;
  }

  &__hostHeader {
    @include flex(flex-start);
    flex-direction: column;
  }

  &__divider {
    padding-bottom: 4px;
  }

  &__label {
    @include text($H10, 400, $LIGHT_NEUTRALS_11);
    display: inline-block;
    margin-bottom: 10px;
  }

  &__edit-icon {
    color: $LIGHT_NEUTRALS_6;
    @include transition(color);
    cursor: pointer;

    &:hover {
      color: $LIGHT_NEUTRALS_7;
    }
  }

  &__block-wrapper {
    margin-top: 40px;
  }

  &__remove {
    color: $LIGHT_NEUTRALS_9;
    font-size: 12px;
    position: relative;
    bottom: 5px;
    @include transition(color);

    &:hover {
      color: $LIGHT_NEUTRALS_6;
    }
  }

  .ProjectOriginOriginRow {
    max-width: 100%;
  }
}
