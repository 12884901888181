@import 'src/theme/core';

$style: ProjectOriginTrafficDrawerContent;

.#{$style} {
  &__block {
    padding: 16px 24px;
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  }
  &__title {
    @include text($H13, 500, $LIGHT_NEUTRALS_7);
    margin-bottom: 16px;
  }
  &__subtitle {
    @include text($H10, 400, $LIGHT_NEUTRALS_7);
    margin-bottom: 12px;
  }
  &__label {
    @include text($H10, 400, $LIGHT_NEUTRALS_11);
    margin-bottom: 15px;
    &._small {
      margin-bottom: 0;
      color: $LIGHT_NEUTRALS_6;
      margin-top: 5px;
    }
    &._mt {
      margin-top: 7px;
    }
  }
  &__table {
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  &__table-head {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 16px 24px 11px;
  }
  &__table-name {
    @include text($H10, 500, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
    &._initial-case {
      text-transform: initial;
      display: inline-block;
      margin-left: 12px;
    }
  }
  &__table-item {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 8px 24px 10px;
    &._last {
      border-bottom: none;
    }
  }
  &__table-col {
    &._text-alight-right {
      text-align: right;
    }
  }
  &__table-value {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
  }
  &__table-icon {
    color: $LIGHT_NEUTRALS_6;
    cursor: pointer;
    @include transition(color);
    &:hover {
      color: $LIGHT_NEUTRALS_11;
    }
  }
  &__empty-state {
    @include flex(center, center);
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    padding: 11px;
  }
  &__sector {
    padding: 10px 0;
    position: relative;
  }
  &__sector-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -24px;
    height: 32px;
    width: 251px;
    background: $LIGHT_NEUTRALS_1;
    border: 1px solid $LIGHT_NEUTRALS_4;
    border-radius: 4px;
    @include flex(space-between, center);
    padding: 0 12px;
  }
  &__sector-label {
    @include text($H13, 400, $LIGHT_NEUTRALS_11);
  }
  &__sector-icon {
    color: $LIGHT_NEUTRALS_6;
    font-size: 16px;
    cursor: pointer;
    @include transition(color);
    &:hover {
      color: $LIGHT_NEUTRALS_11;
    }
  }
  &__hidden {
    display: none;
  }
  &__label-name {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    & > span {
      color: $LIGHT_NEUTRALS_11;
    }
  }
}
