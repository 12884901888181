@import 'src/theme/core';
.draft {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  // padding: 0 24px;
  // margin: 0 -24px;
  background: $LIGHT_NEUTRALS_0;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 4px;
  max-height: 100%;
}
.head {
  padding: 15px 24px;
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
}
.content {
  padding: 24px;
}

.icon {
  @include text($H18, 400, $LIGHT_RED_ACCENT);
  &.orange {
    color: $LIGHT_ORANGE_ACCENT;
  }
  &.red {
    color: $LIGHT_RED_ACCENT;
  }
  &.green {
    color: $LIGHT-GREEN_3;
  }
  &.blue {
    color: $LIGHT_BLUE_3_ORIGINAL;
  }
  &.yellow {
    color: $LIGHT_YELLOW_1;
  }
}
.item {
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  &.active {
    background-color: $LIGHT_NEUTRALS_3;
  }
}
.button {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  margin: 0;
  &.disabled {
    color: $LIGHT_NEUTRALS_6;
  }
}
.name {
  @include text($H16, 600, $LIGHT_NEUTRALS_11);
  margin: 0;
  &.disabled {
    color: $LIGHT_NEUTRALS_6;
  }
}
.dropdown {
  position: absolute;
  right: 40px;
  top: 66px;
}

.card {
  border: 1px solid $LIGHT_BLUE_2;
  border-radius: 4px;
  padding: 16px;
  @include flex(flex-start, flex-start);
  height: 96px;
  cursor: pointer;
  &.active {
    border-color: $LIGHT_BLUE_4;
  }
  &.disabled {
    background: $LIGHT_NEUTRALS_2;
    cursor: not-allowed;
    .modalTitle {
      color: $LIGHT_NEUTRALS_6;
    }
    .modalText {
      color: $LIGHT_NEUTRALS_6;
    }
  }
}
.modalIcon {
  margin-right: 4px;
  font-size: 16px;
}
.modalTitle {
  @include text($H16, 600, $LIGHT_NEUTRALS_9);
  margin: 0 0 6px;
  line-height: 1;
}
.modalText {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
}
