@import 'src/theme/core';
$style: ProjectOrigin;
.#{$style} {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // margin: 0 -20px;
  padding: 16px;
  position: relative;
  // &__service-btn {
  //   .Button__icon {
  //     color: $LIGHT_NEUTRALS_7 !important;
  //     font-size: 18px !important;
  //     position: relative;
  //     top: 0.5px;
  //   }
  //   .Button__text {
  //     color: $LIGHT_NEUTRALS_11 !important;
  //     font-size: 18px !important;
  //   }
  // }
  // &__section {
  //   padding: 20px 24px;
  //   border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  //   &._last {
  //     border-bottom: none;
  //   }
  // }
  // &__checkbox {
  //   margin-bottom: 12px;
  // }
  // &__label {
  //   @include text($H12, 400, $LIGHT_NEUTRALS_7);
  //   margin: 0;
  // }
  // &__section-title {
  //   @include text($H16, 500, $LIGHT_NEUTRALS_11);
  //   margin-bottom: 12px;
  // }
  // &__sidebar {
  //   position: fixed;
  //   left: 32px;
  //   top: 105px;
  //   bottom: 32px;
  //   background-color: $LIGHT_NEUTRALS_0;
  //   width: 260px;
  //   border-radius: 8px;
  //   padding: 32px 20px;
  //   border: 1px solid $LIGHT_NEUTRALS_3;
  // }
  // &__list {
  //   list-style: none;
  //   padding: 0;
  //   margin: 0;
  // }
  // &__link {
  //   display: inline-block;
  //   width: 100%;
  //   padding: 14px 12px;
  //   border-radius: 8px;
  //   @include flex(flex-start, center);
  //   @include text($H16, 600, $LIGHT_NEUTRALS_7);
  //   &._active {
  //     background-color: $LIGHT_BLUE_1;
  //     color: $LIGHT_BLUE_5;
  //   }
  //   &._disabled {
  //     color: $LIGHT_NEUTRALS_6;
  //     cursor: not-allowed;
  //   }
  // }
  // &__link-icon {
  //   font-size: 16px;
  //   margin-right: 8px;
  // }
}
