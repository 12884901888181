@import 'src/theme/core';

@-webkit-keyframes ButtonSpinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes ButtonSpinner {
  to {
    transform: rotate(360deg);
  }
}

$style: Button;

$names: 'primary', 'secondary', 'subtle', 'danger';

// lIGHT
$bg: (
  'primary-light': $LIGHT_BLUE_6,
  'secondary-light': $LIGHT_NEUTRALS_0,
  'subtle-light': transparent,
  'danger-light': $LIGHT_RED_ACCENT,
  'primary-dark': $DARK_YELLOW_ORIGINAL,
  'secondary-dark': $DARK_NEUTRALS_11,
  'subtle-dark': transparent,
);
$bg-hover: (
  'primary-light': $LIGHT_BLUE_5,
  'secondary-light': $LIGHT_NEUTRALS_0,
  'subtle-light': transparent,
  'danger-light': $DARK_RED_ACCENT,
  'primary-dark': $DARK_YELLOW_1,
  'secondary-dark': $DARK_NEUTRALS_11,
  'subtle-dark': transparent,
);
$bg-active: (
  'primary-light': $LIGHT_BLUE_7,
  'secondary-light': $LIGHT_NEUTRALS_0,
  'subtle-light': transparent,
  'danger-light': $LIGHT_RED_ACCENT,
  'primary-dark': $DARK_YELLOW_2,
  'secondary-dark': $DARK_NEUTRALS_11,
  'subtle-dark': transparent,
);
$bg-disabled: (
  'primary-light': $LIGHT_NEUTRALS_4,
  'secondary-light': $LIGHT_NEUTRALS_2,
  'subtle-light': transparent,
  'danger-light': $LIGHT_NEUTRALS_4,
  'primary-dark': $DARK_NEUTRALS_10,
  'secondary-dark': $DARK_NEUTRALS_10,
  'subtle-dark': transparent,
);
$color: (
  'primary-light': $LIGHT_NEUTRALS_0,
  'secondary-light': $LIGHT_NEUTRALS_7,
  'subtle-light': $LIGHT_BLUE_6,
  'danger-light': $LIGHT_NEUTRALS_0,
  'primary-dark': $DARK_NEUTRALS_0,
  'secondary-dark': $DARK_NEUTRALS_2,
  'subtle-dark': $DARK_BLUE_ORIGINAL,
);
$icon-color: (
  'primary-light': $LIGHT_NEUTRALS_0,
  'secondary-light': $LIGHT_NEUTRALS_6,
  'subtle-light': $LIGHT_BLUE_6,
  'danger-light': $LIGHT_NEUTRALS_0,
  'primary-dark': $DARK_NEUTRALS_0,
  'secondary-dark': $DARK_NEUTRALS_2,
  'subtle-dark': $DARK_BLUE_ORIGINAL,
);
$color-hover: (
  'primary-light': $LIGHT_NEUTRALS_0,
  'secondary-light': $LIGHT_BLUE_5,
  'subtle-light': $LIGHT_BLUE_4,
  'danger-light': $LIGHT_NEUTRALS_0,
  'primary-dark': $DARK_NEUTRALS_0,
  'secondary-dark': $DARK_YELLOW_1,
  'subtle-dark': $DARK_BLUE_1,
);
$color-active: (
  'primary-light': $LIGHT_NEUTRALS_0,
  'secondary-light': $LIGHT_BLUE_7,
  'subtle-light': $LIGHT_BLUE_7,
  'danger-light': $LIGHT_NEUTRALS_0,
  'primary-dark': $DARK_NEUTRALS_0,
  'secondary-dark': $DARK_YELLOW_2,
  'subtle-dark': $DARK_BLUE_2,
);
$color-disabled: (
  'primary-light': $LIGHT_NEUTRALS_6,
  'secondary-light': $LIGHT_NEUTRALS_5,
  'subtle-light': $LIGHT_NEUTRALS_5,
  'danger-light': $LIGHT_NEUTRALS_6,
  'primary-dark': $DARK_NEUTRALS_8,
  'secondary-dark': $DARK_NEUTRALS_8,
  'subtle-dark': $DARK_NEUTRALS_8,
);
$border: (
  'primary-light': $LIGHT_BLUE_6,
  'secondary-light': $LIGHT_NEUTRALS_3,
  'subtle-light': transparent,
  'danger-light': $LIGHT_RED_ACCENT,
  'primary-dark': $DARK_YELLOW_ORIGINAL,
  'secondary-dark': $DARK_NEUTRALS_10,
  'subtle-dark': transparent,
);
$border-hover: (
  'primary-light': $LIGHT_BLUE_5,
  'secondary-light': $LIGHT_NEUTRALS_3,
  'subtle-light': transparent,
  'danger-light': $DARK_RED_ACCENT,
  'primary-dark': $DARK_YELLOW_1,
  'secondary-dark': $DARK_NEUTRALS_10,
  'subtle-dark': transparent,
);
$border-active: (
  'primary-light': $LIGHT_BLUE_7,
  'secondary-light': $LIGHT_NEUTRALS_3,
  'subtle-light': transparent,
  'danger-light': $LIGHT_RED_ACCENT,
  'primary-dark': $DARK_YELLOW_2,
  'secondary-dark': $DARK_NEUTRALS_10,
  'subtle-dark': transparent,
);
$border-disabled: (
  'primary-light': $LIGHT_NEUTRALS_4,
  'secondary-light': $LIGHT_NEUTRALS_3,
  'subtle-light': transparent,
  'primary-dark': $LIGHT_NEUTRALS_10,
  'danger-light': $LIGHT_NEUTRALS_4,
  'secondary-dark': $DARK_NEUTRALS_10,
  'subtle': transparent,
);

$icon: 'svg:not(.#{$style}__loading)';

@mixin setButtonTheme($theme) {
  @each $name in $names {
    $mode: #{$name}-#{$theme};
    &._#{$name} {
      background-color: map-get($bg, $mode);
      border-color: map-get($border, $mode);
      .#{$style}__text {
        color: map-get($color, $mode);
      }
      .#{$style}__icon {
        color: map-get($icon-color, $mode);
      }
      .#{$style}__loading {
        stroke: map-get($color, $mode);
      }
    }
    @include media($screen-desktop) {
      &._#{$name}:hover:not(._loading, :disabled) {
        background-color: map-get($bg-hover, $mode);
        border-color: map-get($border-hover, $mode);
        &._only-child:not(._primary) {
          border-color: map-get($color-hover, $mode);
        }
        .#{$style}__text {
          color: map-get($color-hover, $mode);
        }
        .#{$style}__icon {
          color: map-get($color-hover, $mode);
        }
      }
    }
    &._#{$name}:active:not(._loading, :disabled) {
      background-color: map-get($bg-active, $mode);
      border-color: map-get($border-active, $mode);
      .#{$style}__text {
        color: map-get($color-active, $mode);
      }
      .#{$style}__icon {
        color: map-get($color-active, $mode);
      }
    }
    &._#{$name}:disabled {
      background-color: map-get($bg-disabled, $mode);
      border-color: map-get($border-disabled, $mode);
      cursor: not-allowed;
      .#{$style}__text {
        color: map-get($color-disabled, $mode);
      }
      .#{$style}__icon {
        color: map-get($color-disabled, $mode);
        fill: map-get($color-disabled, $mode);
      }
    }
    &._#{$name}._loading {
      .#{$style}__loading {
        visibility: visible;
        opacity: 1;
        display: block;
      }
    }
  }
}

.#{$style} {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  @include flex(center, center);
  display: inline-flex;
  @include transition(background-color, border-color);
  &:not(._subtle) {
    border: 1px solid transparent;
    padding: 0 13px 2px;
    border-radius: 4px;
    min-height: 32px;
    box-shadow: 0px 2px 2px rgba(75, 88, 103, 0.03);
    // .#{$style}__text {
    //   position: relative;
    //   bottom: -1px;
    // }
  }
  &._only-child {
    padding: 0 6px;
  }
  &._loading {
    color: transparent;
    cursor: not-allowed;
    pointer-events: none;
    > *:not(.#{$style}__loading) {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }
  &._fluid {
    width: 100%;
  }
  &._secondary {
    .#{$style}__text {
      font-weight: 400;
    }
  }
  &._small {
    .#{$style}__icon {
      font-size: 16px;
    }
    .#{$style}__text {
      font-size: $H10;
    }
  }
  &._smaller {
    width: 20px;
    height: 20px;
    min-height: 20px !important;
    padding: 0;
    .#{$style}__icon {
      font-size: 14px;
    }
  }
  &._big:not(._subtle) {
    min-height: 48px;
  }
  &._big {
    .#{$style}__text {
      font-size: $H16;
    }
  }
  &._subtle {
    .#{$style}__loading {
      @include size(12px);
    }
  }
  &._subtle._small {
    .#{$style}__loading {
      @include size(10px);
    }
  }
  &._active {
    border-color: $LIGHT_BLUE_6 !important;
    .#{$style}__text {
      color: $LIGHT_BLUE_6 !important;
    }
    .#{$style}__icon {
      color: $LIGHT_BLUE_6 !important;
    }
  }

  // Children
  &__icon {
    margin-right: 6px;
    font-size: 18px;
    @include transition(color);
    &._only-child {
      margin: 0;
    }
    &._right {
      order: 2;
      margin-right: 0;
      margin-left: 6px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: $H12;
    @include transition(color, opacity);
    line-height: 1;
  }
  &__loading {
    display: none;
    position: absolute;
    @include svg(20px);
    fill: none;
    stroke-dasharray: 40px;
    stroke-dashoffset: 42px;
    stroke-width: 2px;
    z-index: 1;
    stroke-linecap: round;
    animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite ButtonSpinner;
  }
  @include setButtonTheme('light');
}

html.dark .#{$style} {
  @include setButtonTheme('dark');
  &._primary:disabled {
    border-color: $DARK_NEUTRALS_10;
  }
  &._secondary {
    box-shadow: 0px 2px 2px #25282c;
    @include hover {
      box-shadow: 0px 2px 3px rgba(37, 40, 44, 0.8);
    }
    &:active {
      box-shadow: 0px 2px 4px rgba(37, 40, 44, 0.7);
    }
  }
}
