@import 'src/theme/core';
$style: Dropdown;

.#{$style} {
  font-size: 12px;
  min-width: 125px;
  max-width: 400px;

  &._small {
    min-width: 50px;

    & .ant-tooltip-inner {
      min-width: 25px;
      padding: 8px;
      min-height: 24px;
    }

    &.ant-tooltip-placement-right {
      padding-left: 4px !important;
    }
  }

  &.ant-tooltip-placement-bottomLeft {
    padding-top: 0 !important;
  }

  & .ant-tooltip-arrow {
    display: none;
  }

  & .ant-tooltip-inner {
    padding: 16px;
    background: $LIGHT_NEUTRALS_0;
    box-shadow: 0px 6px 24px 2px rgba(22, 32, 39, 0.11);
    border-radius: 4px;
  }

  &._arrow {
    & .ant-tooltip-arrow {
      display: block;
    }

    & .ant-tooltip-arrow-content {
      &::before {
        background: $LIGHT_NEUTRALS_0;
      }
    }
  }
}

html.dark .#{$style} {
  & .ant-tooltip-inner {
    background: $DARK_NEUTRALS_12;
    border: 1px solid $DARK_NEUTRALS_10;
  }
}
