@import 'src/theme/core';

$style: Project;

.#{$style} {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  // margin: 0 -20px;
  // bottom: 7px;
  // position: relative;

  &__controls {
    margin-bottom: 1px;
  }

  &__chart-wrapper {
    margin: 0 -4px 0 -16px;

    &._big {
      height: 240px;
    }
  }

  &__palceholder {
    position: absolute;
    @include position();
    @include flex(center, center);
    height: 100%;
  }

  &__progress {
    position: absolute;
    top: 42px;
    left: 32px;
    right: 32px;
    width: 95.5%;
  }
}
