@import 'src/theme/core';
.item {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  padding: 14px 24px;
}
.col {
  &.taRight {
    text-align: right;
  }
}
.label {
  @include text($H12, 400, $LIGHT_NEUTRALS_10);
}
.badge {
  margin-left: 12px;
}
.linkIcon {
  color: $LIGHT_NEUTRALS_6;
  margin-left: 6px;
  @include transition(color);
  cursor: pointer;
  &:hover {
    color: $LIGHT_NEUTRALS_7;
  }
  &.marginLess {
    margin-left: 0;
  }
}
.modalText {
  @include text($H13, 400, $LIGHT_NEUTRALS_10);
  text-align: center;
  span {
    font-weight: 600;
  }
}
