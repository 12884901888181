@import 'src/theme/core';
.name {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  & > span {
    font-weight: 600;
  }
}

.coupon {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
}

.icon {
  color: $LIGHT_NEUTRALS_5;
  &.active {
    color: $LIGHT_NEUTRALS_9;
  }
}

.dropdown {
    margin: -8px -16px;
    width: 250px;
}

.dropdownHead {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  padding: 0 8px 8px;
}

.dropdownTitle {
  @include text($H12, 600, $LIGHT_NEUTRALS_9);
  display: block;
  margin: 0;
  padding: 0;
}

.dropdownPrice {
  @include text($H16, 600, $LIGHT_BLUE_6);
}

.dropdownContent {
    padding: 8px 8px 0;
}

.iconCheck {
  color: $LIGHT-GREEN_3;
}

.dropdownText {
//   white-space: break-spaces;
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  line-height: 1;
}
