@import 'src/theme/core';

.trigger {
  padding: 5px 0px;
}

.route {
  @include flex(flex-start, center);
  // gap: 8px;
  @include text($H14, 600, $DARK_NEUTRALS_7);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  height: 28px;
  transition: all 0.2s ease;
  font-weight: 400;
  // padding-left: 15px;
  border-radius: 4px;

  &:hover {
    color: $DARK_NEUTRALS_7;
    background-color: $LIGHT_NEUTRALS_3;
    // border-bottom: 1px solid white;
    transition: all 0.2s ease;
  }

  &.active {
    color: $DARK_NEUTRALS_7;
    // color: $LIGHT_BLUE_6;
    background-color: $LIGHT_BLUE_2;
    transition: all 0.2s ease;

    // &::after {
    //   content: '';
    //   width: 3px;
    //   height: 100%;
    //   background-color: $LIGHT_BLUE_6;
    //   position: absolute;
    //   left: 0px;
    // }
  }

  .routeIcon {
    font-size: 16px;
    padding: 6px 8px;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}

.subroutes {
  overflow: hidden;

  .route {
    padding-left: 16px;
  }

  &.collapsed {
    padding: 0;
    width: 120px;
  }
}

.col {
  @include flex(flex-start, center);
  // gap: 8px;
}


.ant-tooltip .Dropdown .ant-tooltip-content {
  padding: 0 !important;

  .subroutes {
    .route {
      padding-left: 0px !important;
    }
  }
}
