@import 'src/theme/core';
$style: ProjectChartsMapTooltip;
.#{$style} {
  min-width: 350px;
  // margin: -8px -22px;
  padding: 8px 0;
  &__head {
    @include flex(flex-start, center);
    margin-bottom: 10px;
    gap: 4px;
  }
  &__head-title {
    @include text($H10, 500, $LIGHT_NEUTRALS_11);
    margin: 0;
  }
  &__stats {
    margin-bottom: 12px;
  }
  &__stats-row {
    @include flex(flex-start, center);
    gap: 4px;
  }
  &__stats-name {
    @include text($H10, 500, $LIGHT_NEUTRALS_7);
  }
  &__stats-value {
    @include text($H10, 400, $LIGHT_NEUTRALS_6);
  }
  &__table {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  }
  &__table-row {
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-bottom: none;
    &._border-bottom {
      border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    }
  }
  &__table-head-col {
    padding: 4px 8px;
    border-right: 1px solid $LIGHT_NEUTRALS_3;
    &:last-child {
      border-right: none;
    }
  }
  &__table-head-name {
    @include text($H8, 500, $LIGHT_NEUTRALS_6);
  }
  &__table-first-col {
    padding: 4px 8px;
    border-right: 1px solid $LIGHT_NEUTRALS_3;
  }
  &__table-name-value {
    @include text($H10, 500, $LIGHT_NEUTRALS_10);
  }
  &__table-value {
    border-right: 1px solid $LIGHT_NEUTRALS_3;
    padding: 4px 8px;
    span {
      @include text($H10, 500, $LIGHT_NEUTRALS_10);
    }
    &:last-child {
      border-right: none;
    }
  }
  &__table-double-row {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  }
}
