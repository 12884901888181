@import 'src/theme/core';
.linkIcon {
  color: $LIGHT_NEUTRALS_6;
  margin-left: 6px;
  @include transition(color);
  cursor: pointer;
  &:hover {
    color: $LIGHT_NEUTRALS_7;
  }
  &.marginLess {
    margin-left: 0;
  }
}
.modalText {
  @include text($H13, 400, $LIGHT_NEUTRALS_10);
  text-align: center;
  span {
    font-weight: 600;
  }
}
