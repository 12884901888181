@import 'src/theme/core';

.footer {
  @include flex(flex-end, center);
  gap: 16px;
  border-top: 1px solid $LIGHT_NEUTRALS_3;
  padding: 16px 24px 0;
  margin: 0 -24px;
}

.title {
  @include flex(center, center, column);
  @include text($H16, 400, $LIGHT_NEUTRALS_9);
  margin-bottom: 12px;
  span {
    display: block;
    text-align: center;
    font-size: $H11;
    color: $LIGHT_RED_ACCENT;
  }
}
.text {
  @include text($H14, 400, $LIGHT_NEUTRALS_7);
  span {
    @include text($H14, 700, $LIGHT_NEUTRALS_7);
  }
}
