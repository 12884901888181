@import 'src/theme/core';

.ProjectOriginEnvIdDetails {
    .title {
        margin: 0px;
    }

    .coppy-block {
        height: 32px;
        background: $LIGHT_NEUTRALS_1;
        border: 1px solid $LIGHT_NEUTRALS_4;
        border-radius: 4px;
        padding: 0 9px 0 12px;
        @include flex(space-between, center);
        width: 300px;
        margin-bottom: 16px;
        font-size: 13px;
    }

    .edit-icon {
        color: $LIGHT_NEUTRALS_6;
        @include transition(color);
        cursor: pointer;
        font-size: 18px;

        &:hover {
            color: $LIGHT_NEUTRALS_7;
        }
    }
}
