@import 'src/theme/core';

.wrapper {
  position: relative;
}

.row {
  padding: 12px 24px;
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  flex-wrap: nowrap;
  z-index: 1;
  position: relative;
  justify-content: space-between;
  &.item {
    @include transition(background-color);
    &:hover {
      background: $LIGHT_BLUE_1;
    }
  }
  :nth-child(1) {
    max-width: 70px;
  }
  :nth-child(2) {
    max-width: 70px;
  }
  :nth-child(3) {
    max-width: 150px;
    word-break: break-word;
    span{
      min-width: 100%;
    }
  }
  :nth-child(4) {
    justify-content: flex-start !important;
    word-break: break-word;
    min-width: 500px;
    max-width: max-content;
    span{
      min-width: 100%;
    }
  }
  :nth-child(5) {
    max-width: 50px
  }
}
.label {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}
.col {
  @include flex(flex-start, center);
  overflow: hidden;
  &.right {
    justify-content: flex-end;
  }
  &.pl {
    padding-left: 100px;
  }
}
.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
}
.labelElement {
  width: 60px;
}
.icon {
  font-size: 15px;
  color: $LIGHT_NEUTRALS_6;
}
.modalText {
  @include text($H13, 400, $LIGHT_NEUTRALS_10);
  text-align: center;
}
.button {
  @include flex(center, center);
}
.iconDropdown {
  font-size: 16px;
  margin-left: 8px;
  @include transition(transform);
}
.firstItem {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: -8px;
    right: -8px;
    background: $LIGHT_NEUTRALS_3;
    height: 1px;
  }
}
.title {
  @include text($H18, 400, $LIGHT_NEUTRALS_11);
  margin-bottom: 12;
}
.text {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  margin-bottom: 20;
}

.emptyState {
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 236px;
  right: 0;
  padding: 16px 32px 32px 32px;
  box-shadow: 0px 8px 32px rgba(51, 65, 100, 0.14);
  border-radius: 10px 0px 0px 10px;
  background-color: $DARK_NEUTRALS_0;
  &.collapsed {
    left: 100px;
  }
}
.emptyTitle {
  @include text($H32, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 20px;
}
.emptyText {
  @include text($H16, 400, $LIGHT_NEUTRALS_7);
  margin-bottom: 36px;
}
.imageBlock {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    background: radial-gradient(
        58.66% 51.3% at 48.74% 51.37%,
        rgba(255, 199, 129, 0.7) 13.54%,
        rgba(255, 199, 129, 0.5) 39.58%,
        rgba(255, 199, 129, 0.3) 66.87%,
        rgba(255, 199, 129, 0) 87.5%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    opacity: 0.6;
    filter: blur(40px);
    left: -6%;
    top: -30%;
    z-index: 0;
  }
  &:after {
    content: '';
    position: absolute;
    width: 520px;
    height: 520px;
    background: radial-gradient(
        51.16% 52.01% at 49.02% 48.46%,
        #387bff 0%,
        rgba(56, 123, 255, 0.49) 35.94%,
        rgba(56, 123, 255, 0.12) 66.87%,
        rgba(56, 123, 255, 0) 89.92%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    opacity: 0.6;
    filter: blur(4px);
    right: -1%;
    bottom: -30%;
  }
}
.ilustration {
  position: relative;
  z-index: 1;
  width: 100%;
}
.empty {
  @include flex(center, center, column);
  padding: 64px 32px;
}
.coppyBlock {
  height: 32px;
  background: $LIGHT_NEUTRALS_2;
  border: 1px solid $LIGHT_NEUTRALS_4;
  border-radius: 4px;
  padding: 0 9px 0 12px;
  @include flex(space-between, center);
  width: 100%;
}
.coppyLabel {
  @include text($H13, 400, $LIGHT_NEUTRALS_6);
}
.coppyIcon {
  color: $LIGHT_NEUTRALS_6;
  font-size: 20px;
  @include transition(color);
  &:hover {
    color: $LIGHT_NEUTRALS_8;
  }
}
.namespaceBlock {
  padding: 14px 16px;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.namespaceCounter {
  @include flex(center, center);
  background-color: $LIGHT_BLUE_6;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  @include text($H10, 600, $LIGHT_NEUTRALS_0);
}
.namespaceName {
  @include text($H14, 600, $LIGHT_NEUTRALS_10);
}
.namespaceText {
  @include text($H14, 400, $LIGHT_NEUTRALS_9);
  display: inline-block;
}
.namespaceDescr {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
}
.namespaceList {
  padding-left: 12px;
}
.namespaceItem {
  margin-bottom: 6px;
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  & > span {
    font-weight: 700;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
