@import 'src/theme/core';
$style: ProjectOriginKey;
.#{$style} {
  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_11);
    margin-bottom: 14px;
    &._last {
      margin-bottom: 0;
    }
  }
  &__label {
    @include text($H10, 400, $LIGHT_NEUTRALS_11);
    display: inline-block;
    margin-bottom: 10px;
  }
  &__edit-icon {
    color: $LIGHT_NEUTRALS_6;
    @include transition(color);
    cursor: pointer;
    font-size: 18px;
    &:hover {
      color: $LIGHT_NEUTRALS_7;
    }
  }
  &__coppy-block {
    height: 32px;
    background: $LIGHT_NEUTRALS_1;
    border: 1px solid $LIGHT_NEUTRALS_4;
    border-radius: 4px;
    padding: 0 9px 0 12px;
    @include flex(space-between, center);
    width: 300px;
    margin-bottom: 16px;
  }
  &__coppy-label {
    @include text($H13, 400, $LIGHT_NEUTRALS_11);
  }
  &__btn {
    margin-bottom: 18px;
  }
}
