@import 'src/theme/core';
.row {
  padding: 8px 24px;
  margin: 0 -24px;
  cursor: pointer;
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  flex-wrap: nowrap;
  &.item {
    @include transition(background-color);
    &:hover {
      background: $LIGHT_BLUE_1;
    }
  }
  &.active {
    background: $LIGHT_BLUE_1;
  }
}
.col {
  @include flex(flex-start, center);
  overflow: hidden;
  &.right {
    justify-content: flex-end;
  }
  &.pl {
    padding-left: 100px;
  }
}
.label {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}
.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
