@import 'src/theme/core';
$style: HomeHeader;

.#{$style} {
  padding: 16px;
  &__list {
    @include flex(flex-start, flex-start, column);
    margin: 0;
    padding: 0;
  }
  &__item {
    @include flex(flex-start, center);
    margin-bottom: 8px;
    &._active {
      .#{$style}__list-value {
        color: $LIGHT_BLUE_6;
      }
      .#{$style}__list-icon {
        display: initial;
      }
    }
    &._fixed {
      width: 100px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__list-value {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    // &._disabled {
    //   opacity: 0.6;
    //   color: $DARK_NEUTRALS_7;
    //   cursor: default;
    // }
  }
  &__btn {
    width: 100%;
    @include flex(flex-start, center);
    padding-left: 24px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
  }
  &__list-icon {
    color: $LIGHT_BLUE_6;
    display: none;
    position: absolute;
    left: 0;
  }
  &__dropdown-title {
    @include text($H12, 400, $LIGHT_NEUTRALS_6);
    display: inline-block;
    padding-left: 24px;
    margin-bottom: 8px;
  }
  &__checkbox {
    padding-left: 6px;
  }
}
