@import 'src/theme/core';
$style: HomeTable;

.#{$style} {
  padding: 16px;
  height: 100%;

  // margin: 0 -32px;
  &._empty {
    flex: 1;
  }

  &__head {
    @include text($H10, 600, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 0 16px;
  }

  &__head-item {
    @include flex(flex-start, center);

    &._last {
      justify-content: flex-end;
      gap: 8px;
    }
  }

  &__head-icon {
    font-size: $H16;
    color: $LIGHT_NEUTRALS_6;
    transition: ease-in-out 0.2s;

    &._big {
      font-size: 20px;
    }

    &._yellow {
      color: $LIGHT_YELLOW_2;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.2) rotate(5deg);
    }
  }

  &__table-head-name {
    @include text($H10, 600, $LIGHT_NEUTRALS_6);

  }

  &__head-name {
    @include text($H14, 600, $LIGHT_NEUTRALS_6);

    &._dark {
      color: $LIGHT_NEUTRALS_10;
    }
  }

  &__empty-state {
    @include flex(center, center, column);
    height: 100%;

    .emptyState {
      .ant-empty-description {
        color: $LIGHT_NEUTRALS_6;
        font-weight: 500;
      }
    }
  }

  &__empty-text {
    @include text($H10, 600, $LIGHT_NEUTRALS_6);
    margin-top: 20px;
  }

  &__item-list {
    background-color: $LIGHT_NEUTRALS_0;
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 4px;
    padding: 0 14px;
    transition: ease-in-out 0.2s;

    &:hover {
      cursor: pointer;
      background-color: $LIGHT_BLUE_1;
    }
  }

  &__item-col {
    @include flex(flex-start, center);
    padding: 16px 0;

    &._last {
      justify-content: flex-end;
      gap: 8px;
    }
  }

  &__item-row {
    display: flex;
  }

  &__item-icon {
    min-width: 40px;
  }

  &__item-info {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 0 !important;
  }

  &__table {
    height: 100%;
    // padding: 0 10px 0 18px;
  }

  &__table-scrollbox {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 0 0 48px;
  }

  &__card {
    height: 210px;
    background-color: $LIGHT_NEUTRALS_0;
    padding: 10px 0;
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 4px;
    overflow: hidden;
  }

  &__card-head {
    padding: 0 12px;
  }

  &__card-chart {
    height: 90px;
    margin: 0 -20px;
  }

  &__card-loader {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 80%;
  }

  &__card-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    padding: 12px 12px 12px;
    border-top: 1px solid $LIGHT_NEUTRALS_3;
    position: relative;
    z-index: 1;
    transition: ease-in-out 0.2s;

    button {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      background-color: $LIGHT_BLUE_1;
    }
  }
}

.domainName {
  justify-content: flex-start;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 15px;
  width: 50px;
}

.domainNameText {
  display: block;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 14px;
  height: 20px;
}
