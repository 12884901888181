@import 'src/theme/core';
$style: UserSettingsDeleteAccount;

.#{$style} {
  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_10);
    margin-bottom: 12px;
  }
  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_6);
    margin-bottom: 10px;
  }
  &__btn {
    width: 106px;
  }
}
