@import 'src/theme/core';
$style: ProjectChartsControls;
.#{$style} {
    @include flex(space-between, center);
    &__wrapper {
       @include flex(flex-start, center);
    }
    &__label {
        @include text($H12, 400, $LIGHT_NEUTRALS_11);
        margin-right: 8px;
    }
    &__col {
        @include flex(flex-start, center);
        gap: 16px;
    }
}