@import 'src/theme/core';
.routing {
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 16px;
}
.chartWrapper {
  height: calc(100vh - 92px) !important;
}
.chart {
  padding-bottom: 24px;
}
.button {
  @include flex(center, center);
}
.icon {
  font-size: 16px;
  margin-left: 8px;
  @include transition(transform);
}
