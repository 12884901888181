@import 'src/theme/core';

.page {
  // height: calc(100% - 80px);
  overflow-y: auto;
  // margin: 0 -24px;
  padding: 16px;
  overflow-x: hidden;

  &.full {
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0px;
    z-index: 100;
    padding: 0 20px;
    background: $LIGHT_NEUTRALS_0;
  }
}

.chartWrapper {
  margin-bottom: 20px;
  overflow: visible !important;
}

.emptyState {
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 236px;
  right: 0;
  padding: 16px 32px 32px 32px;
  box-shadow: 0px 8px 32px rgba(51, 65, 100, 0.14);
  border-radius: 10px 0px 0px 10px;
  background-color: $DARK_NEUTRALS_0;

  &.collapsed {
    left: 100px;
  }
}

.emptyTitle {
  @include text($H32, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 20px;
}

.emptyText {
  @include text($H16, 400, $LIGHT_NEUTRALS_7);
  margin-bottom: 36px;
}

.imageBlock {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 350px;
    height: 350px;
    background: radial-gradient(58.66% 51.3% at 48.74% 51.37%,
        rgba(255, 199, 129, 0.7) 13.54%,
        rgba(255, 199, 129, 0.5) 39.58%,
        rgba(255, 199, 129, 0.3) 66.87%,
        rgba(255, 199, 129, 0) 87.5%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    opacity: 0.6;
    filter: blur(40px);
    left: -6%;
    top: -30%;
    z-index: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 520px;
    height: 520px;
    background: radial-gradient(51.16% 52.01% at 49.02% 48.46%,
        #387bff 0%,
        rgba(56, 123, 255, 0.49) 35.94%,
        rgba(56, 123, 255, 0.12) 66.87%,
        rgba(56, 123, 255, 0) 89.92%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    opacity: 0.6;
    filter: blur(4px);
    right: -1%;
    bottom: -30%;
  }
}

.ilustration {
  position: relative;
  z-index: 1;
  width: 100%;
}

.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chartType {
  display: flex;
  align-items: center;
}

.chartTypeTitle {
  background-color: rgb(171, 171, 171);
  border: 1px solid grey;
  border-right-width: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 3px 5px;
}

.selectInputField {
  // width: 100%;
  // padding: 8px 12px;
  // border: 1px solid $LIGHT_NEUTRALS_3;
  // border-radius: 3px;
  // background-color: $LIGHT_NEUTRALS_0;
  // color: $LIGHT_NEUTRALS_7;
  // font-size: 14px;
  // font-weight: 400;
  // line-height: 20px;
  // outline: none;
  // transition: all 0.3s ease-in-out;
  // cursor: pointer;
  border-color: red !important;
  outline: none;
  border-radius: 40px;


  &:hover {
    border-color: red !important;
    border-radius: 40px;

  }

  &::selection {
    background-color: red;
  }

  &:focus {
    border-color: red;
  }
}

.showFieldDropDown {
  background-color: yellow;
  width: 100%;
  position: absolute;
  z-index: 9;
  top: 0px;
  left: 40px;

  ul {
    list-style: none;

    li {
      padding: 2px 5px;
    }
  }
}

.btnDividers {
  background-color: $LIGHT_NEUTRALS_3;
  width: 15px;
  height: 1px;
  position: relative;
  top: 16px;
  // right: 1px;
}

.addGroupByBtn {
  width: 32px;
  height: 32px;

  svg {
    path {
      fill: $LIGHT_NEUTRALS_5;
      transition: all 0.17s ease-in-out;
    }
  }

  &:hover {
    &:not([disabled]) {
      border: 1px solid $LIGHT_NEUTRALS_6 !important;

      svg {
        path {
          fill: $LIGHT_NEUTRALS_6;
          transition: all 0.17s ease-in-out;

        }
      }
    }

  }
}

.calendarIcon {
  svg {
    path {
      transition: all 0.17s ease-in-out;
      fill: $LIGHT_NEUTRALS_6 !important;
    }
  }

  &:hover {
    svg {
      path {
        transition: all 0.17s ease-in-out;
        fill: $LIGHT_BLUE_6 !important;
      }
    }
  }
}

.rangePicker {
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.customLabel {
  position: absolute;
  top: -10px;
  right: 195px;
}

.rangeDateBtnList {
  list-style: none;
  width: 130px;
  margin: 0;
}

.customRangeWrapper {
  position: absolute;
  z-index: 0;
  width: 200px;
  right: 0px;
}

.customRangeBtn {
  font-size: 12px;
  color: $LIGHT_BLUE_6;
  display: block;
  background-color: #fff;
  height: 23px;
  z-index: 1;
  position: relative;
  right: 12px;
  min-width: 200px;
  text-align: end;
  line-height: 23px;

  span {
    cursor: pointer;
  }
}
