@import 'src/theme/core';

.wrapper {
  background-color: $LIGHT_NEUTRALS_1;
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  &.empty {
    border-bottom-color: transparent
  }
}
.button {
  border: none;
  outline: none;
  background: none;
  padding: 8px 24px;
  cursor: pointer;
  @include text($H12, 600, $LIGHT_NEUTRALS_10);
  &:hover {
    color: $LIGHT_NEUTRALS_11;
  }
}
.icon {
  font-size: 20px;
  color: $LIGHT_NEUTRALS_6;
  position: relative;
  left: -5px;
  top: 3px;
  transform: rotate(-90deg);
  &.active {
    transform: rotate(0);
  }
}
