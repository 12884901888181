%hidden-input {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  left: 4px;
  top: 4px;
}

%scroll-block {
  height: 100%;
  overflow-y: auto;
  margin: 0 -20px;
  padding: 20px 20px;
}
