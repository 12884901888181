@import 'src/theme/core';
$style: ProjectEngineItem;
.#{$style} {
  // padding: 0 0 0 24px;
  &._elseif {
    padding: 0 16px 20px;
    margin: 0 -16px;
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    position: relative;
  }
  &._bordertop {
    border-top: 1px solid $LIGHT_NEUTRALS_3;
    padding-top: 20px;
    margin-top: 20px;
  }
  &__item-wrapper {
    @include flex(stretch, stretch);
  }
  &__item-line {
    width: 2px;
    background: $LIGHT_BLUE_6;
    border-radius: 1px;
    margin-right: 16px;
    &._feature {
      background: $LIGHT-GREEN_3;
    }
  }
  &__item-body {
    @include flex(flex-start, flex-start, column);
    flex: 1;
  }
  &__item-label {
    @include flex(center, center);
    border-radius: 4px;
    background-color: $LIGHT_BLUE_6;
    padding: 3px 8px;
    color: $LIGHT_NEUTRALS_0;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  &__item-controls {
    @include flex(flex-start, flex-start);
    position: absolute;
    right: 4px;
  }
  &__item-check {
    color: $LIGHT-GREEN_3;
    margin-right: 10px;
  }
  &__item-delete {
    @include flex(flex-start, center);
    color: $LIGHT_NEUTRALS_6;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    @include transition(color);
    &[disabled] {
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      color: $LIGHT_NEUTRALS_11;
      .#{$style}__trash-icon {
        color: $LIGHT_NEUTRALS_11;
      }
    }
  }
  &__trash {
    @include size(24px, true);
    background-color: $LIGHT_NEUTRALS_2;
    margin-right: 6px;
    @include flex(center, center);
  }
  &__trash-icon {
    color: $LIGHT_NEUTRALS_6;
    @include transition(color);
  }
  &__item-row {
    @include flex(flex-start, center);
  }
  &__switch {
    position: relative;
    top: -5px;
  }
  &__item-subtle {
    position: absolute;
    bottom: -28px;
  }
}
