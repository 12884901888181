@import 'src/theme/core';

.searchLogs {
  @include flex(flex-start, center);
  margin-bottom: 20px;
  gap: 12px;

}

.lableBlock {
  position: relative;
  @include text($H12, 400, $DARK_NEUTRALS_7);
  text-wrap: nowrap;
  white-space: nowrap;
  padding: 0 13px;
  border: 1px solid $LIGHT_NEUTRALS_4;
  border-radius: 4px 0 0 4px;
  background-color: $LIGHT_NEUTRALS_2;
  height: 32px;
  line-height: 30px;
  border-right: none;
}

.groupBy {
  position: absolute;
  background-color: $LIGHT_NEUTRALS_4;
  height: 69px;
  width: 1px;
  bottom: -70px;
  left: 5px;
}

.block {
  @include flex(flex-start, center);
  height: 32px;
  background: $LIGHT_NEUTRALS_1;
  border: 1px solid $LIGHT_NEUTRALS_4;
  border-radius: 0 4px 4px 0;
  padding: 0 40px;
  position: relative;
}

.errorBlock {
  border: 1px solid rgba(165, 42, 42, .26);
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 8px;
  color: $LIGHT_NEUTRALS_6;
}

.searchIconDropDown {
  position: relative;
  top: 1px;
  color: $LIGHT_NEUTRALS_6;
  padding-right: 3px;
}

.clearIcon {
  position: absolute;
  right: 12px;
  top: 8px;
  color: $LIGHT_NEUTRALS_6;
  cursor: pointer;
  @include transition(color);

  &:hover {
    color: $LIGHT_NEUTRALS_11;
  }
}

.input {
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
}

.tags {
  @include flex(flex-start, center);
  margin-right: 12px;
  gap: 4px;
}

.dropDown {
  display: block;
  top: 33px;
  width: calc(100% - 16px);
  max-height: 246px;
  height: max-content;
  background-color: #fff;
  position: absolute;
  z-index: 999;
  border: solid 1px $LIGHT_NEUTRALS_4;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 14px 2px rgba(22, 32, 39, 0.11);

  .dropDownList {
    max-height: 182px;
    min-height: 52px;
    height: auto;
    overflow: auto;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 2px 11px 2px 5px;
      margin: 0;
      list-style: none;
    }
  }
}

.isHidden {
  display: none;
}

.dropDownItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: $LIGHT_BLUE_1;
  }
}

.dropDownEmptyItem {
  color: $DARK_NEUTRALS_6;
}

.dropDownFooter {
  background-color: $LIGHT_NEUTRALS_2;
  font-size: 15px !important;
}

.dropDownFooter1 {
  padding: 2px 5px;
  border-top: solid 1px $LIGHT_NEUTRALS_4;

  div {
    font-size: 13px;
  }
}

.dropDownFooter2 {
  padding: 4px 5px;
  font-weight: 500;

  div {
    font-size: 13px;
  }
}

.footer1Label {
  color: $DARK_NEUTRALS_14;

}

.footer1Service {
  color: $DARK_NEUTRALS_7;
  font-weight: 600;
}

.footer1Colon {
  color: #c2129d;
  font-weight: 600;

}

.footer1LabelData {
  font-weight: 600;
  color: $LIGHT_NEUTRALS_7;
}

.dropDownFooterItem {
  font-size: 12px;
}

.active {
  background-color: $LIGHT_BLUE_1;
}

.footer2Border {
  border: solid 1px $LIGHT_NEUTRALS_4;
  padding: 1px 4px;
  border-radius: 5px;
  background-color: #fff;
  // box-shadow: 0px 4px 14px 2px rgba(22, 32, 39, 0.11);
}
