.wrapperChart {
  &.origin {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.grayed {
    opacity: 0.3;
    pointer-events: none;
    filter: grayscale(60%);
    transition: all 0.3s ease-in-out;
  }
}
