@import 'src/theme/core';

.title {
  @include text($H16, 600, $LIGHT_NEUTRALS_7);
  margin: 0;
  padding: 0;
}

.button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  @include flex(center, center);
  width: 22px;
  height: 22px;
  position: absolute;
  right: 28px;
  top: 32px;
}

.icon {
  font-size: 16px;
  color: $LIGHT_NEUTRALS_6;
  @include transition(color);

  &:hover {
    color: $LIGHT_NEUTRALS_7;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: $LIGHT_NEUTRALS_0;
  @include text($H16, 600, $LIGHT_NEUTRALS_7);
  margin: 0;
  padding: 32px;
  @include flex(flex-start, center);
  width: 100%;
  // border-bottom: 1px solid $LIGHT_NEUTRALS_3;
}
