@import 'src/theme/core';

.subtitle {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  margin-bottom: 10px;
  @include flex(flex-start, center);
  gap: 4px;
}
.selected {
  @include flex(flex-start, center);
  @include text($H16, 600, $LIGHT_NEUTRALS_9);
}
.icon {
  font-size: 16px;
  margin-right: 6px;
}
.card {
  @include flex(flex-start, flex-start, column);
  padding: 18px;
  border: 1px solid $LIGHT_BLUE_2;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
  &.active {
    border-color: $LIGHT_BLUE_3_ORIGINAL;
  }
  &.disabled {
    background-color: $LIGHT_NEUTRALS_2;
    cursor: not-allowed;
    .name {
      color: $LIGHT_NEUTRALS_6;
    }
    .descr {
      color: $LIGHT_NEUTRALS_6;
    }
  }
}
.iconModal {
  font-size: 20px;
}
.name {
  @include text($H16, 600, $LIGHT_NEUTRALS_9);
  margin-bottom: 8px;
}
.descr {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  margin-bottom: 0;
}
