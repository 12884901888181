@import 'src/theme/core';
$style: Modal;
.#{$style} {
  & .ant-modal-content {
    background: $LIGHT_NEUTRALS_0;
    box-shadow: 0px 4px 14px 2px rgba(22, 32, 39, 0.11);
    border-radius: 8px;
  }
  & .ant-modal-close {
    color: $DARK_NEUTRALS_14;
  }
  & .ant-modal-header {
    border-radius: 8px 8px 0 0;
    .ant-modal-title {
      @include text($H16, 500, $DARK_NEUTRALS_14);
    }
  }
  &__content {
    padding: 24px;
  }
  &__footer {
    @include flex(flex-end, center);
    padding: 16px 24px;
    border-top: 1px solid $LIGHT_NEUTRALS_3;
    &._centered {
      @include flex(center, center);
    }
  }
  &__footer-btn {
    margin-right: 18px;
  }
  &._danger {
    & .ant-modal-header {
      .ant-modal-title {
        color: $LIGHT_RED_ACCENT;
      }
    }
  }
  &._hideHeader {
    & .ant-modal-close {
      color: $LIGHT_NEUTRALS_6;
    }
    & .ant-modal-header {
      display: none;
    }
  }
}
html.dark .#{$style} {
  .ant-modal-content {
    background: $DARK_NEUTRALS_12;
  }
  .ant-modal-header {
    background-color: $DARK_NEUTRALS_12;
    border-bottom-color: $DARK_NEUTRALS_10;
    .ant-modal-title {
      color: $DARK_NEUTRALS_1;
    }
  }
  .ant-modal-close {
    color: $DARK_NEUTRALS_8;
  }
  &__footer {
    border-top-color: $DARK_NEUTRALS_10;
  }
}
