@import 'src/theme/core';
.title {
  @include text($H16, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 14px;
}
.row {
  margin-bottom: 0px;
}
.bannerWrapper
.switchText {
  @include text($H12, 400, $LIGHT_NEUTRALS_11);
}
.boldText {
  @include text($H12, 600, $LIGHT_NEUTRALS_11);
  &.warning {
    color: $LIGHT_RED_ACCENT;
  }
}
.label {
  @include text($H12, 600, $LIGHT_NEUTRALS_9);
}
