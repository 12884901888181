@import 'src/theme/core';

.createPropertyWrapper {
  padding: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 0.4s ease;
  max-height: 100vh;

  .ProjectOriginOrigin {
    max-height: 100vh;
  }
}
