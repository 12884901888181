@import 'src/theme/core';

$style: Checkbox;

.#{$style} {
  position: relative;
  cursor: pointer;
  @include flex(flex-start, center);
  display: inline-flex;
  &._big {
    .#{$style}__area {
      @include size(18px);
      border-radius: 3px;
      .#{$style}__check {
        font-size: 14px;
      }
    }
  }
  &._big-rounded {
    .#{$style}__area {
      @include size(18px);
      border-radius: 5px;
      .#{$style}__check {
        font-size: 14px;
      }
    }
  }
  &._disabled {
    .#{$style}__area {
      border-color: $LIGHT_NEUTRALS_3;
      background-color: $LIGHT_NEUTRALS_1;
    }
    cursor: not-allowed;
  }
  &:not(._disabled) {
    @include hover {
      .#{$style}__area {
        border-color: $LIGHT_BLUE_3_ORIGINAL;
      }
      .#{$style}__input:checked + .#{$style}__area {
        border-color: $LIGHT_BLUE_3_ORIGINAL;
        background-color: $LIGHT_BLUE_3_ORIGINAL;
      }
    }
  }
  &__input {
    @extend %hidden-input;
    &:checked + .#{$style}__area {
      &._standart {
        border-color: $LIGHT_BLUE_6;
        background-color: $LIGHT_BLUE_6;
      }
      &._g2 {
        background-color: $LIGHT-GREEN_2;
        border-color: $LIGHT-GREEN_2;
      }
      &._g3 {
        background-color: $LIGHT-GREEN_3;
        border-color: $LIGHT-GREEN_3;
      }
      &._purple {
        background-color: #ab61c5;
        border-color: #ab61c5;
      }
      &._salad {
        background-color: #97b716;
        border-color: #97b716;
      }
      &._teal {
        background-color: #57bddd;
        border-color: #57bddd;
      }
      &._y1 {
        background-color: $LIGHT_YELLOW_1;
        border-color: $LIGHT_YELLOW_1;
      }
      &._y3 {
        background-color: $LIGHT_YELLOW_3;
        border-color: $LIGHT_YELLOW_3;
      }
      &._ra {
        background-color: $LIGHT_RED_ACCENT;
        border-color: $LIGHT_RED_ACCENT;
      }
      &._oa {
        background-color: $LIGHT_ORANGE_ACCENT;
        border-color: $LIGHT_ORANGE_ACCENT;
      }
      &._b3 {
        background-color: $LIGHT_BLUE_3;
        border-color: $LIGHT_BLUE_3;
      }
      &._pink {
        background-color: #e177c4;
        border-color: #e177c4;
      }
      &._light-pink {
        background-color: #f2bef3;
        border-color: #f2bef3;
      }
      &._mustard {
        background-color: #d1bf7d;
        border-color: #d1bf7d;
      }
      .#{$style}__check {
        display: block;
      }
    }
    &:checked ~ .#{$style}__text {
      color: $LIGHT_NEUTRALS_11;
    }
    &:disabled + .#{$style}__area {
      border-color: $LIGHT_NEUTRALS_4;
      background-color: $LIGHT_NEUTRALS_4;
    }
  }
  &__area {
    @include size(10px);
    @include flex(center, center);
    background-color: $LIGHT_NEUTRALS_0;
    border-radius: 2px;
    border: 1px solid $LIGHT_NEUTRALS_3;
    box-shadow: 0px 2px 2px rgba(75, 88, 103, 0.03);
    @include transition(background-color, border-color);
  }
  &__check {
    display: none;
    align-self: center;
    font-size: 8px;
    color: $LIGHT_NEUTRALS_0;
    animation: scaleIn 0.4s ease;
  }
  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    @include transition(color);
    margin-left: 8px;
  }
}

html.dark .#{$style} {
  &__area {
    background-color: $DARK_NEUTRALS_11;
    border-color: $DARK_NEUTRALS_7;
    box-shadow: none;
  }
  &__input {
    &:checked + .#{$style}__area {
      border-color: $DARK_BLUE_ORIGINAL;
      background-color: $DARK_BLUE_ORIGINAL;
      .#{$style}__check {
        display: block;
      }
    }
    &:checked ~ .#{$style}__text {
      color: $DARK_NEUTRALS_1;
    }
    &:disabled + .#{$style}__area {
      border-color: $DARK_NEUTRALS_4;
      background-color: $DARK_NEUTRALS_4;
    }
  }
  &__text {
    color: $DARK_NEUTRALS_4;
  }
  &:not(._disabled) {
    @include hover {
      .#{$style}__area {
        border-color: $DARK_NEUTRALS_4;
      }
      .#{$style}__input:checked + .#{$style}__area {
        border-color: $DARK_BLUE_1;
        background-color: $DARK_BLUE_1;
      }
    }
  }
}
