@import 'src/theme/core';

$style: Label;

$height: 18px;

$types: 'default', 'danger', 'warning', 'success', 'info';

$bg: (
  'default-light': $LIGHT_NEUTRALS_3,
  'danger-light': $LIGHT_RED_1,
  'success-light': $LIGHT_GREEN_1,
  'warning-light': $LIGHT_ORANGE_1,
  'info-light': $LIGHT_BLUE_2,
  'default-dark': $DARK_NEUTRALS_8,
  'danger-dark': $DARK_RED_4,
  'success-dark': $DARK_GREEN_4,
  'warning-dark': $DARK_ORANGE_4,
  'info-dark': $DARK_BLUE_2,
);

$color: (
  'default-light': $LIGHT_NEUTRALS_7,
  'danger-light': $LIGHT_RED_ACCENT,
  'success-light': $LIGHT_GREEN_3,
  'warning-light': $LIGHT_ORANGE_4,
  'info-light': $LIGHT_BLUE_6,
  'default-dark': $DARK_NEUTRALS_1,
  'danger-dark': $DARK_RED_ACCENT,
  'success-dark': $DARK_GREEN_2,
  'warning-dark': $DARK_ORANGE_ACCENT,
  'info-dark': $LIGHT_BLUE_6,
);

@mixin setLabelTheme($theme) {
  @each $type in $types {
    $mode: #{$type}-#{$theme};
    &._#{$type} {
      background-color: map-get($bg, $mode);
      .#{$style}__text {
        color: map-get($color, $mode);
      }
      .#{$style}__icon {
        fill: map-get($color, $mode);
        color: map-get($color, $mode);
      }
    }
  }
}

.#{$style} {
  height: $height;
  padding: 0 8px;
  white-space: nowrap;
  @include flex(center, center);
  display: inline-flex;
  border-radius: 4px;
  @include transition(background-color);
  &__text {
    font-size: $H10;
    font-weight: 400;
    line-height: $height;
    vertical-align: center;
    @include transition(color);
  }
  &__icon {
    font-size: 10px;
    display: inline-flex;
    margin-right: 4px;
    @include transition(fill);
  }
  @include setLabelTheme('light');
}

html.dark .#{$style} {
  @include setLabelTheme('dark');
}
