@import 'src/theme/core';

.row {
  padding: 8px 24px;
  cursor: pointer;
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  flex-wrap: nowrap;
  position: relative;
  &.item {
    @include transition(background-color);
    &:hover {
      background: $LIGHT_BLUE_1;
    }
  }

  &.active {
    background: $LIGHT_BLUE_1;
  }
  &::before {
    content: " ";
    position: absolute;
    height: 20px;
    width: 4px;
    top: 8px;
    left: 8px;
    border-radius: 6px;
  }
  &.bg5xx {
    &::before {
      background: $LIGHT_RED_ACCENT;
    }
  }
  &.bg4xx {
    &::before {
      background: $LIGHT_ORANGE_ACCENT;
    }
  }
  &.bg3xx {
    &::before {
      background: $LIGHT-GREEN_3;
    }
  }
  
  &.bg2xx {
    &::before {
      background: $LIGHT_BLUE_3_ORIGINAL;
    }
  }
}
.col {
  @include flex(flex-start, center);
  overflow: hidden;
  &.right {
    justify-content: flex-end;
  }
  &.pl {
    padding-left: 100px;
  }
}
.icon {
  color: $LIGHT_NEUTRALS_6;
  font-size: 14px;
  margin-left: 4px;
  @include transition(color, transform);
  &.active {
    color: $LIGHT_BLUE_6;
  }
  &.rotate {
    transform: rotate(180deg);
  }
}
.label {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}
.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.code code span[class='hljs-string'] {
  color: $LIGHT_NEUTRALS_9;
}
.code code span[class='hljs-number'] {
  color: $LIGHT-GREEN_3;
}
.date {
  @include text($H13, 400, $LIGHT_NEUTRALS_7);
  span {
    color: $LIGHT_NEUTRALS_6;
  }
}
.empty {
  @include flex(center, center);
  @include text($H14, 400, $LIGHT_NEUTRALS_7);
  min-height: calc(100vh - 510px);
}
.emptyData {
  @include flex(center, center);
  @include text($H14, 400, $LIGHT_NEUTRALS_7);
  min-height: calc(100vh - 510px);
  flex-direction: column;
}
.wrapper {
  height: calc(100vh - 507px);
  overflow-y: auto;
  &.withoutChart {
    height: calc(100vh - 407px);
  }
  &.withoutChart.isFull {
    height: calc(100vh - 320px);
  }
  &.grayed {
   opacity: 0.6;
   pointer-events: none;
   filter: grayscale(60%);
   transition: all 0.2s ease-in-out; 
  }
  &.origin {
    opacity: 1;
    transition: all 0.2s ease-in-out; 
   }
}
