/* ---FONT SIZE--- */
@function calcRem($size) {
  $remSize: calc($size / 16px);
  @return #{$remSize}rem;
}

$H32: calcRem(32px);
$H30: calcRem(30px);
$H24: calcRem(24px);
$H22: calcRem(22px);
$H20: calcRem(20px);
$H19: calcRem(19px);
$H18: calcRem(18px);
$H17: calcRem(17px);
$H16: calcRem(16px);
$H15: calcRem(15px);
$H14: calcRem(14px);
$H13: calcRem(13px);
$H12: calcRem(12px);
$H11: calcRem(11px);
$H10: calcRem(10px);
$H9: calcRem(9px);
$H8: calcRem(8px);

// FONTS
$base-font: 'Assistant', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
