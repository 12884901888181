@import 'src/theme/core';
$style: ProjectChartsCheckboxes;
.#{$style} {
  &__list {
    @include flex(flex-start, center, row, wrap);
    margin: 0;
    list-style: none;
    max-width: 476px;
  }
  &__item {
    @include flex(flex-start, center);
    padding: 2px;
    max-width: calc(100% / 3);
    width: 100%;
    flex-basis: calc(100% / 3);
  }
}
