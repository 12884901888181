@import 'src/theme/core';
$style: ProjectChartsMapBlock;
.#{$style} {
  @include flex(stretch, stretch);
  height: 100%;

  &__map {
    width: 100%;
    max-width: 400px;
    height: 220px;
    margin: auto;
    overflow: hidden;
    position: relative;
    // &:after {
    //   content: '';
    //   @include position();
    //   z-index: 1;
    //   position: absolute;
    //   box-shadow: inset 0 0 10px 5px white;
    //   pointer-events: none;
    // }
    &._modal {
      height: 80%;
      max-width: 80%;
      margin-bottom: 60px;
    }
  }
  &__block {
    width: 100%;
    max-width: 400px;
    height: 240px;
    margin: auto;
    overflow: hidden;
    position: relative;
    position: absolute;
    @include position();
    right: 120px;
    z-index: 1;
    opacity: 0;
    &._opacity {
      opacity: 1;
      transition: opacity 0.7s ease-in-out;
    }
    &._realtime {
      max-width: initial;
      left: 10px;
      @include flex(center, center);
    }
    &._disabled {
      pointer-events: none !important;
    }
    & > * {
      &:focus {
        outline: none;
      }
      & > * {
        &:focus {
          outline: none;
        }
        & > * {
          &:focus {
            outline: none;
          }
        }
      }
    }
    &._big {
      max-width: calc(100% - 240px);
      height: 340px;
    }
  }
  &__header {
    @include flex(flex-start, flex-start);
    .loader {
      position: relative;
      bottom: 8px !important;
      left: 15px !important;
    }
  }
  &__left-block {
    padding: 16px 16px 24px 24px;
    flex: 1;
  }
  &__right-block {
    border-left: 1px solid $LIGHT_NEUTRALS_3;
    max-width: 140px;
    width: 100%;
    padding: 24px;
    z-index: 3;
    @include flex(center, center, column);
    & > span {
      margin-bottom: 4px;
    }
  }
  @media screen and (max-width: 1400px) {
    &__right-block {
      border-left: 0;
      opacity: 0.8;
    }
  }
  &__tooltip {
    background: $LIGHT_NEUTRALS_0 !important;
    box-shadow: 0px 2px 8px rgba(25, 42, 62, 0.1) !important;
    border-radius: 4px !important;
    &.show {
      opacity: 1 !important;
    }
  }
  &__map-legends {
    @include flex-row(center, center);
    gap: 40px;
  }
  &__legend {
    @include flex-row(center, center);
    gap: 6px;
  }
  &__dot {
    @include size(8px, true);
    background-color: $LIGHT_BLUE_6;
    &._green {
      background-color: $LIGHT-GREEN_3;
    }
  }
  &__text {
    @include text($H10, 500, $LIGHT_NEUTRALS_11);
  }
  &__modal {
    @include flex(stretch, stretch);
    height: 75vh;
    margin: -24px 0;
  }
  // &__palceholder {
  //   position: absolute;
  //   @include position();
  //   @include flex(center, center);
  //   height: 100%;
  // }
  &__title {
    z-index: 2;
  }
  &__loader{
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    left: 160px;
  }
}
