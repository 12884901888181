@import 'src/theme/core';

$style: StatusDot;

.#{$style} {
  display: inline-flex;
  border-radius: 50%;
  @include size(9px);
  @include transition(background-color, box-shadow);
  background-color: $LIGHT_NEUTRALS_0;
  box-shadow: 0px 0px 4px rgba($LIGHT_NEUTRALS_0, 0.7);
  &._success {
    background-color: $LIGHT-GREEN_2;
    box-shadow: 0px 0px 4px rgba($LIGHT-GREEN_2, 0.7);
  }
  &._warning {
    background-color: $LIGHT_YELLOW_ORIGINAL;
    box-shadow: 0px 0px 4px rgba($LIGHT_YELLOW_ORIGINAL, 0.7);
  }
  &._danger {
    background-color: $LIGHT_RED_ACCENT;
    box-shadow: 0px 0px 4px rgba($LIGHT_RED_ACCENT, 0.7);
  }
  &._staging {
    background-color: $LIGHT_NEUTRALS_5;
  }
}
