@import 'src/theme/core';
$style: UserSettings;
.#{$style} {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 -20px;
  padding: 12px 20px;
  position: relative;

  &__page{
    padding: 0px 16px;
    margin-top: 20px;
  }
}
