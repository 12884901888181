@import 'src/theme/core';

.button {
  padding-bottom: 14px;
  padding-top: 12px;
  cursor: pointer;
  @include flex(flex-start, center);
  gap: 14px;
  @include text($H12, 400, $LIGHT_NEUTRALS_10);
  background-color: $LIGHT_NEUTRALS_1;
}

.item {
  // margin-bottom: 6px;
  border: 1px solid $LIGHT_NEUTRALS_3;
}

.icon {
  font-size: 18px;
  color: $LIGHT_NEUTRALS_6;
  transform: rotate(-90deg);

  &.active {
    transform: rotate(0deg);
  }
}

.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_10);
  padding: 12px 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.blue {
    color: $LIGHT_BLUE_6;
  }

  &.red {
    color: $LIGHT_RED_ACCENT;
  }

  .first {
    padding-left: 24px;
  }

  .last {
    padding-right: 24px;
  }
}
