@import 'src/theme/core';
$style: ProjectEngineEngine;
.#{$style} {
  background: $LIGHT_NEUTRALS_0;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 4px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &__head {
    padding: 15px 24px;
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  }
  &__label {
    margin-left: 8px;
  }
  &__name {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
  }
  &__variant {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
  }
  &__content {
    padding: 20px 24px 24px;
  }
  &__items {
    margin: 0 -24px;
    @include flex(stretch, stretch, column);
    position: relative;
  }
  &__add-btn {
    display: none;
  }
  &__code code span[class='hljs-string'] {
    color: $LIGHT_NEUTRALS_9;
  }
  &__code code span[class='hljs-number'] {
    color: $LIGHT-GREEN_3;
  }
}
