@import 'src/theme/core';
.loader {
  width: 48%;
  height: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 9;
  overflow: hidden;
}

.transparency{
  opacity: 0.1;
  transition: opacity 0.4s ease-in-out;
}
.notransparency{
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

