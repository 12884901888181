@import 'src/theme/core';
.row {
  padding: 12px 24px;
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  flex-wrap: nowrap;
  &.item {
    @include transition(background-color);
    justify-content: space-between;
    &:hover {
      background: $LIGHT_BLUE_1;
    }
  }
  &.last {
    border-bottom-color: transparent;
  }
  :nth-child(1) {
    max-width: 70px;
  }
  :nth-child(2) {
    max-width: 70px;
  }
  :nth-child(3) {
    max-width: 150px;
    word-break: break-word;
    span{
      min-width: 100%;
    }
  }
  :nth-child(4) {
    justify-content: flex-start !important;
    word-break: break-word;
    min-width: 500px;
    span{
      min-width: 100%;
    }
  }
  :nth-child(5) {
    max-width: 50px
  }
}
.label {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}
.col {
  @include flex(flex-start, center);
  overflow: hidden;
  &.right {
    justify-content: flex-end;
  }
  &.pl {
    padding-left: 100px;
  }
}
.value {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
}
.labelElement {
  width: 60px;
}
.icon {
  font-size: 15px;
  color: $LIGHT_NEUTRALS_6;
}
