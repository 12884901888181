@import 'src/theme/core';
$style: Steps;
.#{$style} {
  &._big &__step {
    & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      font-size: $H13;
      padding-top: 3px;
    }
    &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      @include size(32px);
    }
    &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
      font-size: $H14;
    }
    &.ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      top: 15px;
    }
  }
  &._big {
    &.ant-steps-item-wait .ant-steps-item-icon {
      @include size(32px);
    }
    &.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
      @include size(32px);
    }
    &.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
      font-size: $H13;
    }
  }
  & &__step {
    & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      @include text($H10, 400, $LIGHT_NEUTRALS_7);
      font-family: $base-font;
    }
    &.ant-steps-item-active
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: $LIGHT_NEUTRALS_11;
    }
    &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      @include size(24px);
      border-radius: 4px;
      background-color: $LIGHT_BLUE_6;
      @include flex(center, center);
      display: inline-flex;
    }
    &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
      font-size: 12px;
      color: $LIGHT_NEUTRALS_0;
      @include flex(center, center);
    }
    &.ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: $LIGHT_NEUTRALS_3;
    }
    &.ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: $LIGHT_NEUTRALS_3;
    }
    &.ant-steps-item-wait .ant-steps-item-icon {
      @include size(24px);
      border-radius: 4px;
      background-color: $LIGHT_NEUTRALS_3 !important;
      border-color: $LIGHT_NEUTRALS_3;
      @include flex(center, center);
      display: inline-flex;
    }
    &.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: $LIGHT_NEUTRALS_7 !important;
      font-family: $base-font;
      @include flex(center, center);
    }
  }
  &.ant-steps-small .ant-steps-item-title {
    padding-right: 18px;
  }
  &.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 18px;
    &:first-child {
      padding-left: 0;
    }
  }
  &.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    @include size(24px);
    background-color: $LIGHT_NEUTRALS_3;
    border-radius: 4px;
    @include flex(center, center);
    display: inline-flex;
  }
  &.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    @include text($H10, 500, $LIGHT_NEUTRALS_11);
    @include flex(center, center);
    width: initial;
    height: initial;
    position: relative;
    left: 0px;
  }
}
html.dark .#{$style} {
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: $DARK_NEUTRALS_6;
    border-color: $DARK_NEUTRALS_10;
  }
  &__step {
    & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: $DARK_NEUTRALS_7;
    }
    &.ant-steps-item-active
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: $DARK_BLUE_ORIGINAL;
    }
    &.ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: $DARK_NEUTRALS_10;
    }
    &.ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: $DARK_NEUTRALS_10;
    }
    &.ant-steps-item-wait .ant-steps-item-icon {
      background-color: $DARK_NEUTRALS_10 !important;
      border-color: $DARK_NEUTRALS_10;
    }
    &.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      @include text($H10, 500, $LIGHT_NEUTRALS_6!important);
      font-family: $base-font;
    }
  }
  &.ant-steps-small .ant-steps-item-title {
    padding-right: 18px;
  }
  &.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 18px;
  }
}
