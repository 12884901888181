// lIGHT MODE
$LIGHT_NEUTRALS_0: #ffffff;
$LIGHT_NEUTRALS_1: #fafbfc;
$LIGHT_NEUTRALS_2: #f5f6f8;
$LIGHT_NEUTRALS_3: #ebeff2;
$LIGHT_NEUTRALS_4: #dfe5ed;
$LIGHT_NEUTRALS_5: #c2cfe0;
$LIGHT_NEUTRALS_6: #90a0b7;
$LIGHT_NEUTRALS_7: #445a84;
$LIGHT_NEUTRALS_8: #424d63;
$LIGHT_NEUTRALS_9: #334164;
$LIGHT_NEUTRALS_10: #334164;
$LIGHT_NEUTRALS_11: #192a3e;

$LIGHT_BLUE_0: #eff8fe;
$LIGHT_BLUE_1: #f2f6fd;
$LIGHT_BLUE_2: #dde6f9;
$LIGHT_BLUE_3: #b2c8f3;
$LIGHT_BLUE_3_ORIGINAL: #0079d3;
$LIGHT_BLUE_4: #055d9e;
$LIGHT_BLUE_5: #3b72df;
$LIGHT_BLUE_6: #1a59d9;
$LIGHT_BLUE_7: #0a4ccf;
$LIGHT_BLUE_8: #0843b7;
$LIGHT_BLUE_BORDER: #3394dc;

$LIGHT_LIGHT_GREEN_ACCENT: #9ac6a9;

$LIGHT-GREEN_1: #e1eee5;
$LIGHT-GREEN_2: #9ac6a9;
$LIGHT-GREEN_3: #549568;

$LIGHT_YELLOW_1: #fbc447;
$LIGHT_YELLOW_ORIGINAL: #ecb22e;
$LIGHT_YELLOW_2: #d8a430;
$LIGHT_YELLOW_3: #d8a430;

$LIGHT_ORANGE_ACCENT: #ec892e;
$LIGHT_ORANGE_1: #fce7ce;
$LIGHT_ORANGE_4: #e48139;

$LIGHT_RED_ACCENT: #db5960;
$LIGHT_RED_1: #f8dedf;

$LIGHT_LEFTBAR_PROPERTIES: #E8F1F8;
$LIGHT_LEFTBAR_PAGES: #F2F8FC;

// DARK MODE
$DARK_NEUTRALS_0: #ffffff;
$DARK_NEUTRALS_1: #f5f7f8;
$DARK_NEUTRALS_2: #d9dde1;
$DARK_NEUTRALS_3: #d5d5d6;
$DARK_NEUTRALS_4: #b7bcbe;
$DARK_NEUTRALS_5: #aaabad;
$DARK_NEUTRALS_6: #969da1;
$DARK_NEUTRALS_7: #656b74;
$DARK_NEUTRALS_8: #5e6265;
$DARK_NEUTRALS_9: #4c5054;
$DARK_NEUTRALS_10: #3c4043;
$DARK_NEUTRALS_11: #323539;
$DARK_NEUTRALS_12: #2b2e31;
$DARK_NEUTRALS_13: #282a2d;
$DARK_NEUTRALS_14: #1f2123;

$DARK_BLUE_ORIGINAL: #46a0e2;
$DARK_BLUE_1: #54b3fb;
$DARK_BLUE_2: #3e7ba8;
$DARK_BLUE_6: #313d48;

$DARK_GREEN_1: #389d66;
$DARK_GREEN_2: #8dc8bc;
$DARK_GREEN_3: #4e746c;
$DARK_GREEN_4: #4c5b55;

$DARK_YELLOW_1: #fac580;
$DARK_YELLOW_ORIGINAL: #f5b45f;
$DARK_YELLOW_2: #d79b4c;

$DARK_ORANGE_ACCENT: #ce813a;
$DARK_ORANGE_4: #4c3f33;

$DARK_RED_ACCENT: #cd6d6b;
$DARK_RED_4: #543c41;
