@import 'src/theme/core';
$style: Divider;
.#{$style} {
  &.ant-divider:not(.ant-divider-vertical) {
    border-top-color: $LIGHT_NEUTRALS_3;
  }
  &.#{$style}.ant-divider-vertical {
    margin: 0 16px;
    min-height: 24px;
    border-left-color: $LIGHT_NEUTRALS_3;
  }
}
html.dark .#{$style} {
  &.ant-divider:not(.ant-divider-vertical) {
    border-top-color: $DARK_NEUTRALS_10;
  }
  &.ant-divider-vertical {
    border-left-color: $DARK_NEUTRALS_10;
  }
}
