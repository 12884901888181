@import 'src/theme/core';
$style: ProjectOriginTraffic;
.#{$style} {
  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    @include flex-row(flex-start, center);
    gap: 8px;
    margin-bottom: 12px;
  }
  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_9);
    margin-bottom: 10px;
    @include flex-row(flex-start, center);
    gap: 4px;
  }
  &__drawer-title {
    @include text($H16, 600, $LIGHT_NEUTRALS_7);
    margin: 0;
    padding: 0;
  }
  &__drawer-icon {
    font-size: 20px;
    position: relative;
    top: -4px;
  }
  &__head {
    padding: 16px 24px;
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    background-color: $LIGHT_NEUTRALS_1;
  }
  &__label {
    @include text($H12, 400, $LIGHT_NEUTRALS_11);
  }
  &__error-label {
    color: $LIGHT_RED_ACCENT;
    @include flex-row(flex-start, center);
    gap: 8px;
  }
  &__warning-icon {
    color: $LIGHT_YELLOW_2;
  }
  &__success-icon {
    color: $LIGHT-GREEN_3;
  }
  &__details {
    background: $LIGHT_NEUTRALS_2;
    border-radius: 4px;
    padding: 10px 16px;
    margin-top: 14px;
  }
  &__details-name {
    @include text($H12, 600, $LIGHT_NEUTRALS_9);
  }
  &__details-value {
    @include text($H12, 400, $LIGHT_NEUTRALS_9);
  }
  &__edit {
    margin-top: 12px;
  }
}
