@import 'src/theme/core';
$style: Switch;
$width: 32px;
$dot-size: 10px;
$padding: 4px;
$border-radius: 9px;
$offset: $width - ($padding * 2) - $dot-size;
$dot-size-big: 12px;
$width-big: 46px;
$padding-big: 5px;
$border-radius-big: 16px;
$offset-big: $width-big - ($padding-big * 2) - $dot-size-big;

.#{$style} {
  position: relative;
  pointer-events: none;
  @include flex(flex-start, flex-start, column);
  display: inline-flex;
  &:disabled {
    cursor: not-allowed;
  }
  &:active {
    .#{$style}__input:not(:checked) + .#{$style}__area {
      .#{$style}__dot {
        transition-duration: 0.4s;
        width: 75%;
        transform-origin: left center;
      }
    }
  }
  &__input {
    position: absolute;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    right: 4px;
    top: 4px;
    &:checked + .#{$style}__area {
      background-color: $LIGHT_BLUE_6;
      .#{$style}__dot {
        transform: translateX($offset);
      }
    }
    &:checked + .#{$style}__area._with-text {
      .#{$style}__dot {
        transform: translateX($offset-big);
      }
      .#{$style}__text {
        transform: translateX(-$offset-big + 8px);
      }
    }
  }
  &__area {
    cursor: pointer;
    position: relative;
    pointer-events: all;
    width: $width;
    @include flex;
    background-color: $LIGHT_NEUTRALS_4;
    border-radius: $border-radius;
    padding: $padding;
    @include transition(background-color);
    &._disabled {
      cursor: not-allowed;
      opacity: 0.5;
      filter: grayscale(50%);
    }
    &._with-text {
      width: $width-big;
      border-radius: $border-radius-big;
      padding: $padding-big;
      .#{$style}__dot {
        @include size($dot-size-big);
      }
    }
  }
  &__dot {
    position: relative;
    display: block;
    @include size($dot-size);
    border-radius: 50%;
    background-color: $LIGHT_NEUTRALS_0;
    @include transition(transform, width);
  }
  &__text {
    position: absolute;
    right: 8px;
    top: 7px;
    @include text($H9, 400, $LIGHT_NEUTRALS_0);
    line-height: 1;
    text-transform: uppercase;
  }
  &__label {
    @include text($H10, 400, $LIGHT_NEUTRALS_11);
    margin-bottom: 16px;
  }
}

html.dark .#{$style} {
  &__area {
    background-color: $DARK_NEUTRALS_9;
  }
  &__input {
    &:checked + .#{$style}__area {
      background-color: $DARK_BLUE_ORIGINAL;
    }
  }
}
