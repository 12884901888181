@import 'src/theme/core';
$style: UserSettingsBilling;

.#{$style} {
  width: 100%;

  &__wrapper {
    // margin-top: 20px;
  }

  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_10);
  }

  &__title-main {
    @include text($H18, 600, $LIGHT_NEUTRALS_11);
    margin-bottom: 4px;
  }

  &__subtitle {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    margin-bottom: 24px;
    display: inline-block;
  }

  &__card {
    // width: 160px;
    height: 86px;
    background: $LIGHT_BLUE_1;
    border: 1px solid $LIGHT_BLUE_2;
    border-radius: 4px;
    padding: 8px 8px 12px 16px;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;

    &._selected {
      opacity: 1;
      background: $LIGHT_BLUE_2;
      border: 1px solid $LIGHT-GREEN_2;
    }

    .anticon-check-circle {
      &:hover {
        cursor: pointer;
        scale: 1.1;
      }
    }

    &:hover {
      opacity: 0.9;

      .#{$style}__remove-icon {
        display: initial;
      }
    }
  }

  &__paymentDetails {
    opacity: 1;
    transition: all 0.14s ease-in-out;

    &._loading {
      opacity: 0.6;
      pointer-events: none;
      filter: grayscale(60%);
      transition: all 0.14s ease-in-out;
    }
  }

  &__form-content {
    max-width: 600px;
  }

  &__card-head {
    margin-bottom: 8px;
  }

  &__card-icon {
    font-size: 22px;
  }

  &__card-number {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    margin-bottom: 0px;
  }

  &__card-date {
    @include text($H12, 400, $LIGHT_NEUTRALS_6);
  }

  &__add-btn {
    width: 100%;
    height: 86px;
    background: $LIGHT_NEUTRALS_0;
    border: 1px solid $LIGHT_BLUE_2;
    border-radius: 4px;
    @include flex(center, center);
    cursor: pointer;
    @include transition(background-color);

    &:hover {
      background-color: $LIGHT_BLUE_1;
    }
  }

  &__add-icon {
    color: $LIGHT_BLUE_2;
    font-size: 20px;
  }

  &__remove-icon {
    color: $LIGHT_BLUE_3;
    cursor: pointer;
    display: none;
    @include transition(color);

    &:hover {
      color: $LIGHT_BLUE_3_ORIGINAL;
    }
  }

  &__loader {
    position: absolute;
    right: -20px;
    top: -22px;
  }

  &__email-row {
    opacity: 0;
    height: 0;

    &._active {
      opacity: 1;
      height: initial;
    }
  }

  &__stripe {
    position: fixed;
    bottom: 8px;
    left: 290px;
  }
}
