@import 'src/theme/core';
$style: Typography;
.#{$style} {
    margin: 0;
    @include text($H16, 400, $LIGHT_NEUTRALS_11);
    &._h1 {
        font-size: 24px;
        font-weight: 500;
    }
    &._h2 {
        font-size: 18px;
        font-weight: 500;
    }
    &._h4 {
        font-size: 16px;
        font-weight: 500;
    }
    &._p {
        font-size: 12px;
        color: $LIGHT_NEUTRALS_7;
        line-height: 1.5;
    }
}