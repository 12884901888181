@import 'src/theme/core';

.ProjectOriginDeploymentDetails {
    .title {
        font-size: 1rem;
        font-weight: 600;
        color: #192a3e;
        margin: 2px 0 7px 0;
    }

    .ProjectOriginDeploymentDetails-logs {
        margin-top: 30px;
    }

    .copyIcon {
        svg {
            fill: $LIGHT_NEUTRALS_3;
            transition: all 0.2 ease;
        }

        &:hover {
            svg {
                fill: $LIGHT_NEUTRALS_1;
                transition: all 0.2 ease;
            }
        }
    }

    .back-button {
        .Button__icon {
            font-size: 12px;
        }
    }
}
