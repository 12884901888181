@import 'src/theme/core';
.head {
  position: absolute;
  right: 40px;
  top: 116px;
}
.label {
  @include text($H12, 400, $LIGHT_NEUTRALS_11);
}

.button {
  @include text($H12, 400, $LIGHT_NEUTRALS_9);
  margin: 0;
}
.name {
  @include text($H14, 500, $LIGHT_NEUTRALS_10);
  margin: 0;
}
.icon {
  @include text($H18, 400, $LIGHT_RED_ACCENT);
  &.orange {
    color: $LIGHT_ORANGE_ACCENT;
  }
  &.green {
    color: $LIGHT-GREEN_3;
  }
  &.blue {
    color: $LIGHT_BLUE_3_ORIGINAL;
  }
  &.yellow {
    color: $LIGHT_YELLOW_1;
  }
}
.item {
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  &.active {
    background-color: $LIGHT_NEUTRALS_3;
  }
  &.disabled {
    cursor: not-allowed;
    & .name {
      color: $LIGHT_NEUTRALS_6 !important;
    }
    & .button {
      color: $LIGHT_NEUTRALS_6 !important;
    }
    & .icon {
      color: $LIGHT_NEUTRALS_6 !important;
    }
  }
}
