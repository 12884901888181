@import 'src/theme/core';

$style: Radio;

$size: 18px;

.#{$style} {
  position: relative;
  cursor: pointer;
  @include flex(flex-start, center);
  display: inline-flex;
  &:not(._disabled) {
    @include hover {
      .#{$style}__area {
        border-color: $LIGHT_BLUE_5;
      }
    }
  }
  &._disabled {
    .#{$style}__area {
      border-color: $LIGHT_NEUTRALS_3;
      background-color: $LIGHT_NEUTRALS_1;
    }
    cursor: not-allowed;
  }
  &__input {
    @extend %hidden-input;
    &:checked + .#{$style}__area {
      border-color: $LIGHT_BLUE_6;
      background-color: $LIGHT_BLUE_6;
      &:after {
        border-color: $LIGHT_BLUE_6;
      }
      .#{$style}__check {
        display: flex;
      }
    }
    &:checked ~ .#{$style}__text {
      color: $LIGHT_NEUTRALS_11;
    }
    &:disabled + .#{$style}__area {
      border-color: $LIGHT_NEUTRALS_4;
      &:after {
        border-color: $LIGHT_NEUTRALS_4;
      }
      .#{$style}__check {
        background-color: $LIGHT_NEUTRALS_4;
      }
    }
  }
  &__area {
    position: relative;
    @include size($size, true);
    @include flex(center, center);
    display: inline-flex;
    background-color: $LIGHT_NEUTRALS_0;
    border: 1px solid $LIGHT_NEUTRALS_3;
    @include transition(background-color, border-color);
    box-shadow: 0px 2px 2px rgba(75, 88, 103, 0.03);
    &:after {
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      @include size($size - 2px, true);
      border: 1px solid transparent;
      @include transition(border-color);
    }
  }
  &__check {
    display: none;
    position: absolute;
    align-self: center;
    @include size(8px, true);
    background-color: $LIGHT_NEUTRALS_0;
    animation: scaleIn 0.4s ease;
  }
  &__text {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    @include transition(color);
    margin-left: 8px;
  }
}

html.dark .#{$style} {
  &__area {
    background-color: $DARK_NEUTRALS_11;
    border-color: $DARK_NEUTRALS_7;
    box-shadow: none;
  }
  &__text {
    color: $DARK_NEUTRALS_4;
  }
  &__input {
    &:checked + .#{$style}__area {
      border-color: $DARK_BLUE_ORIGINAL;
      &:after {
        border-color: $DARK_BLUE_ORIGINAL;
      }
    }
    &:checked ~ .#{$style}__text {
      color: $DARK_NEUTRALS_1;
    }
    &:disabled + .#{$style}__area {
      border-color: $DARK_NEUTRALS_4;
      &:after {
        border-color: $DARK_NEUTRALS_4;
      }
      .#{$style}__check {
        background-color: $DARK_NEUTRALS_4;
      }
    }
  }
  &:not(._disabled) {
    @include hover {
      .#{$style}__area {
        border-color: $DARK_NEUTRALS_4;
      }
      .#{$style}__input:checked + .#{$style}__area {
        border-color: $DARK_BLUE_1;
        &:after {
          border-color: $DARK_BLUE_1;
        }
        .#{$style}__check {
          background-color: $DARK_BLUE_1;
        }
      }
    }
  }
}
