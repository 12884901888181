@import 'src/theme/core';
$style: Home;
.#{$style} {
  &__filter-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  &__col-name {
    @include text($H10, 500, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
  }
  &__icon {
    color: $LIGHT_NEUTRALS_6;
    font-size: 20px;
    position: relative;
    top: 3px;
    margin-left: 3px;
    @include transition(transform);
    &._active {
      transform: rotate(180deg);
    }
  }
}
