@import 'src/theme/core';
$style: SectorButton;

.#{$style} {
  @include flex(stretch, stretch);

  &__title {
    color: black;
    border-radius: 4px 0 0 4px;
  }

  &__connector {
    border-radius: 4px 0 0 4px;

  }

  &__divider {
    background-color: $LIGHT_NEUTRALS_3;
    width: 10px;
    height: 1px;
    position: relative;
    top: 16px;
    // right: 1px;
  }

  &__sector-btn-delete {
    outline: none;
    cursor: pointer;
    position: relative;
    @include flex(center, center);
    display: inline-flex;
    @include transition(color);
    border: 1px solid transparent;
    padding: 0 3px;
    min-height: 32px;
    background-color: $LIGHT_NEUTRALS_0;
    border-color: $LIGHT_NEUTRALS_3;
    box-shadow: 0px 2px 2px rgba(75, 88, 103, 0.03);
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    border-radius: 0 4px 4px 0;
  }

  &__sector-btn {
    outline: none;
    cursor: pointer;
    position: relative;
    @include flex(center, center);
    display: inline-flex;
    @include transition(color);
    border: 1px solid transparent;
    padding: 0 13px;
    min-height: 32px;
    background-color: $LIGHT_NEUTRALS_0;
    border-color: $LIGHT_NEUTRALS_3;
    box-shadow: 0px 2px 2px rgba(75, 88, 103, 0.03);
    @include text($H12, 400, $LIGHT_NEUTRALS_7);

    &._isPill {
      box-shadow: none;
      min-height: 28px;

      &:first-child {
        border-radius: 16px 0 0 16px;
        border-right: none;
      }

      &:last-child {
        border-radius: 0 16px 16px 0;
        padding: 0 8px;
      }
    }

    &._whiteSpace {
      white-space: nowrap
    }

    &._active {
      color: $LIGHT_NEUTRALS_0;
      background-color: $LIGHT_BLUE_6;

      .#{$style}__sector-icon {
        color: $LIGHT_NEUTRALS_0;
      }

      .#{$style}__sector-cross-icon {
        color: $LIGHT_NEUTRALS_0;
      }

      &:hover {

        color: $LIGHT_NEUTRALS_0;

        .#{$style}__sector-icon {
          color: $LIGHT_NEUTRALS_0;
        }

      }


    }

    &._active._isPill {
      color: $LIGHT_NEUTRALS_0;
      background-color: $LIGHT_BLUE_6;

      .#{$style}__sector-icon {
        color: $LIGHT_NEUTRALS_0;
      }

      .#{$style}__sector-cross-icon {
        color: $LIGHT_NEUTRALS_0;
      }
    }

    &._disabled {
      background-color: $LIGHT_NEUTRALS_2;
      color: $DARK_NEUTRALS_8 !important;
      cursor: not-allowed;
    }

    &._title {

      background-color: $LIGHT_NEUTRALS_2;
      color: $LIGHT_NEUTRALS_5;
      cursor: default
    }

    &._small {
      min-height: 24px;
      padding: 0 12px;
    }

    &:not(:first-child):not(:last-child) {
      border-right: none;
    }

    &:not(._disabled):hover {
      color: $LIGHT_BLUE_6;

      .#{$style}__sector-icon {
        color: $LIGHT_BLUE_6;
      }

      // .#{$style}__sector-cross-icon {
      //   color: $LIGHT_NEUTRALS_9;
      // }
      &._active {
        color: $LIGHT_NEUTRALS_1;
        background-color: $LIGHT_BLUE_5;

        .#{$style}__sector-icon {
          color: $LIGHT_NEUTRALS_1;
        }

        .#{$style}__sector-cross-icon {
          color: $LIGHT_NEUTRALS_1;
        }
      }

    }

    &:first-child {
      border-radius: 4px 0 0 4px;
      border-right: none;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      padding: 0 8px;
    }
  }

  &__sector-icon {
    color: $LIGHT_NEUTRALS_6;
    font-size: 16px;
    @include transition(color);

    &._with-text {
      margin-right: 6px;
    }

    &._black {
      @include transition(transform);
      margin-left: 8px;
      position: relative;
      top: 0.5px;
      font-size: 12px;
      margin-right: 6px;
    }
  }

  &__sector-cross-icon {
    position: relative;
    color: $LIGHT_NEUTRALS_6;
    font-size: 16px;
    @include transition(color);

    &:hover {
      color: $LIGHT_NEUTRALS_9;
      @include transition(color);

    }

    &:hover {
      .#{$style}__sector-cross-icon {
        color: $LIGHT_NEUTRALS_9;
      }
    }

  }
}
