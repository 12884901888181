@import 'src/theme/core';

.acl-title {
    @include text($H18, 600, $LIGHT_NEUTRALS_11);
}

.acl-subtitle1 {
    @include text($H14, 400, $LIGHT_NEUTRALS_7);
    padding: 16px 0px 32px;
}

.acl-subtitle2 {
    @include text($H14, 400, $LIGHT_NEUTRALS_7);
    padding-bottom: 32px;
}

.acl-subtitle3 {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    padding-bottom: 16px;
}

.acl-icon {
    color: $LIGHT_BLUE_6;
}

.acl-icon-title {
    @include text($H14, 600, $LIGHT_NEUTRALS_7);
    padding: 13px 0px 8px;
}

.acl-icon-description {
    @include text($H14, 400, $LIGHT_NEUTRALS_7);
}

.button-block {
    padding-top: 32px;

    .Button._danger {
        background-color: #FF6464;
        border-color: #FF6464;
    }
}

.button-text {
    @include text($H12, 600, $LIGHT_NEUTRALS_0);
}
