@import 'src/theme/core';
html {
  touch-action: manipulation;
  background-color: $LIGHT_NEUTRALS_1;
}

*:after,
*:before {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  // scrollbar-color: black transparent;
  scrollbar-color: $LIGHT_NEUTRALS_4 transparent;
  scrollbar-width: thin;
  // transition-duration: 0.3s !important;
  // transition-property: color, background-color, border-color, box-shadow, stroke, fill !important;
  // transition-timing-function: ease !important;
  svg {
    transition: none !important;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: $LIGHT_NEUTRALS_0;
  }
  ::-webkit-scrollbar-thumb {
    background: $LIGHT_NEUTRALS_4;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: $LIGHT_NEUTRALS_5;
  }
}
html.dark {
  background-color: $DARK_NEUTRALS_14;

  * {
    ::-webkit-scrollbar-track {
      background: $DARK_NEUTRALS_12;
    }
    ::-webkit-scrollbar-thumb {
      background: $DARK_NEUTRALS_10;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: $DARK_NEUTRALS_9;
    }
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
button,
textarea,
select {
  font-family: $base-font;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-drawer-close {
  position: absolute;
  right: 16px;
  top: 20px;
}
