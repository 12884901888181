@import 'src/theme/core';

.SelectButton-divider {
  background-color: $LIGHT_NEUTRALS_3;
  width: 10px;
  height: 1px;
  position: relative;
  top: 16px;
}

.SelectButton {
  display: flex;
  position: relative;
  text-wrap: nowrap;
  white-space: nowrap;
  margin-bottom: 5px;

  button:hover {
    border: 1px solid $LIGHT_NEUTRALS_6;
    // background-color: $LIGHT_BLUE_1;
  }

  &._error {
    .SelectButton-button {
      color: $LIGHT_NEUTRALS_6;
      background-color: $LIGHT_RED_1
    }
  }
}

.SelectButton-button {
  @include text($H12, 400, $DARK_NEUTRALS_6);
  line-height: 22px;
  max-width: 300px;
  overflow: hidden;

}

.SelectButton-cross-icon {
  position: relative;
  left: 5px;
  color: $LIGHT_NEUTRALS_6;

  &:hover {
    color: $LIGHT_NEUTRALS_11;
  }
}

.SelectButton-title-wrapper {
  @include text($H12, 400, $DARK_NEUTRALS_8);
  background-color: $LIGHT_NEUTRALS_2;
  cursor: default;
  display: flex;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 4px 0 0 4px;
  border-right: none;
  align-items: center;
  padding: 0 8px;
}

.SelectButton-text-wrapper {
  @include text($H12, 400, $DARK_NEUTRALS_8);
  // background-color: $LIGHT_NEUTRALS_2;
  cursor: default;
  display: flex;
  border: 1px solid $LIGHT_NEUTRALS_3;
  // border-radius: 4px 0 0 4px;
  border-right: none;
  align-items: center;
  padding: 0 8px;
}

.SelectButton-button-wrapper {
  .SelectButton-button {
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 0 4px 4px 0;
    padding: 4px 8px;
    // background-color: $LIGHT_YELLOW_4;
    color: $LIGHT_NEUTRALS_11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;

    &:disabled {
      background-color: $LIGHT_NEUTRALS_3;
      outline: none;
      color: $LIGHT_NEUTRALS_6;
      cursor: not-allowed;

    }
  }
}

.SelectButton-input {
  min-width: 50px;
  max-width: 100%;
}

.SelectButton-dropDown-wrapper {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  box-shadow: 0px 2px 2px rgba(75, 88, 103, 0.03);
  position: absolute;
  border: 1px solid $LIGHT_NEUTRALS_6;
  border-radius: 0 4px 4px 4px;
  max-width: max-content;
  min-width: 60px;
  width: 300px;
  background-color: #fff;
  color: $LIGHT_NEUTRALS_11;
  top: 0;
  z-index: 9;

  input {
    min-width: 50px;
    width: 100%;
    border-radius: 0 3px 0 0;
    padding-left: 8px;
    outline: none;
    border: none;
    border-bottom: 1px solid $LIGHT_NEUTRALS_6;
    height: 31px;
    position: relative;
    background-color: $LIGHT_BLUE_2;
  }

  .SelectButton-dropDown {
    border-radius: 4px;
    list-style: none;
    cursor: pointer;
    width: 100%;
    max-height: 210px;
    overflow: auto;
  }

  .SelectButton-input-cross-icon {
    position: relative;
    right: 16px;
    color: $LIGHT_NEUTRALS_6;
    z-index: 10;
    cursor: pointer;

    &:hover {
      color: $LIGHT_NEUTRALS_11;
    }
  }

  .SelectButton-dropDown-item {
    padding: 4px 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: $LIGHT_BLUE_1;
    }
  }
}

.SelectButton-dropDown-item-active {
  background-color: $LIGHT_BLUE_1;
}
