@import 'src/theme/core';

.page {
  // margin: 0 -24px;
  // padding: 0 24px;
  overflow: hidden;
}
.code {
  font-size: 24px;
}
.chart {
  margin-top: -10px;
}
.chartWrapper {
  margin-bottom: 20px;
}
.empty {
  @include flex(center, center);
  @include text($H14, 400, $LIGHT_NEUTRALS_7);
  padding: 30px 0;
  position: relative;
  top: 25px;
}
.chartLoader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 20px;
}
.chartLoaderAbsolute {
  position: absolute;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  z-index: 100;
  left: 0%;
}
.wrapperChart {
  &.origin {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  &.grayed {
    opacity: 0.3;
    pointer-events: none;
    filter: grayscale(60%);
    transition: all 0.3s ease-in-out;
  }
}
