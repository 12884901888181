@import 'src/theme/core';

.title {
  @include text($H16, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 18px;
}

.input {
  background-color: $LIGHT_NEUTRALS_1;
  border: 1px solid $LIGHT_NEUTRALS_4;
  border-radius: 4px;
  height: 32px;
  padding: 8px 16px;
  @include transition(border-color, box-shadow);
  &.focus {
    box-shadow: 0px 0px 1px 2px rgba(26, 89, 217, 0.1);
    border-color: $LIGHT_BLUE_6;
  }
  &.invalid {
    border-color: $LIGHT_RED_ACCENT;
  }
}
.content {
  @include flex(space-between, stretch, column);
}
.label {
  flex: 1;
  width: 100%;
}
.inputName {
  @include text($H12, 400, $LIGHT_NEUTRALS_11);
  margin-bottom: 8px;
  display: block;
}
.footer {
}
.rightBlock {
  @include flex(center, center);
  background: #f8faff;
  margin: -24px 0;
  right: -24px;
  border-radius: 0 8px 8px 0;
  & > * {
    width: 100%;
  }
}
.stripe {
  width: 78px;
  height: 14px;
}
