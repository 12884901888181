@import 'src/theme/core';

.item {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  padding: 14px 24px;
}

.col {
  &.taRight {
    text-align: right;
  }
}

.label {
  @include text($H12, 400, $LIGHT_NEUTRALS_10);
}

.remove {
  &>span {
    color: $LIGHT_RED_ACCENT !important;
  }
}

.resendBtn {
  svg {
    path {
      fill: $LIGHT_BLUE_6;
    }
  }
}
