@import 'src/theme/core';

$style: diffViewerWrapper;

.#{$style} {
  font-size: 12px;

  &_header {
    display: flex;
    justify-content: space-between;
  }

  &_diffBlock {
    font-size: 12px;
  }
}
