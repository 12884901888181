@import 'src/theme/core';
$style: Error;
.#{$style} {
  min-height: 100vh;
  padding: 24px;
  @include flex(stretch, stretch);
  background-color: $LIGHT_NEUTRALS_2;
  &__wrapper {
    flex: 1;
    @include flex(center, center, column);
    background: $LIGHT_NEUTRALS_0;
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 8px;
  }
  &__title {
    @include text(260px, 500, $LIGHT_BLUE_1);
    margin-bottom: 0;
    line-height: 1.1;
  }
  &__subtitle {
    @include text($H32, 500, $LIGHT_NEUTRALS_7);
    margin-bottom: 16px;
  }
  &__text {
    @include text($H16, 400, $LIGHT_NEUTRALS_7);
    margin: 0;
  }
}

html.dark .#{$style} {
  background-color: $DARK_NEUTRALS_12;
  &__wrapper {
    background: $DARK_NEUTRALS_11;
    border-color: $DARK_NEUTRALS_10;
  }
  &__title {
    color: $DARK_BLUE_2;
  }
  &__subtitle {
    color: $DARK_NEUTRALS_2;
  }
  &__text {
    color: $DARK_NEUTRALS_2;
  }
}
