@import 'src/theme/core';

$style: Input;

.#{$style} {
  @include flex-col;
  & &__input,
  & &__input.ant-input-affix-wrapper {
    background-color: $LIGHT_NEUTRALS_1;
    border: 1px solid $LIGHT_NEUTRALS_4;
    border-radius: 4px;
    height: 32px;
    box-shadow: none;
    @include transition(box-shadow, border-color);
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $LIGHT_NEUTRALS_1 inset !important;
      -webkit-text-fill-color: $LIGHT_NEUTRALS_11;
    }
    @include text($H13, 400, $LIGHT_NEUTRALS_11);
    @include hover {
      border-color: $LIGHT_NEUTRALS_5;
    }
    &._textarea {
      height: 80px;
    }
    &._big {
      height: 48px;
      font-size: $H16;
    }
    & .ant-input {
      background-color: $LIGHT_NEUTRALS_1;
      @include text($H13, 400, $LIGHT_NEUTRALS_11);
      &::placeholder {
        @include text($H13, 400, $LIGHT_NEUTRALS_6);
      }
    }
    &._focused,
    &:focus:not(:disabled) {
      box-shadow: 0px 0px 1px 2px rgba(26, 89, 217, 0.1);
      border-color: $LIGHT_BLUE_6;
      .ant-input-affix-wrapper-focused {
        border-color: $LIGHT_BLUE_6;
      }
    }
    &::placeholder {
      @include text($H13, 400, $LIGHT_NEUTRALS_6);
    }
  }
  & &__input[disabled] {
    border-color: $LIGHT_NEUTRALS_2;
    color: $LIGHT_NEUTRALS_6;

    &::placeholder {
      color: $LIGHT_NEUTRALS_5;
    }
  }
  & &__input .ant-input-disabled:disabled {
    border-color: $LIGHT_NEUTRALS_2;
    color: $LIGHT_NEUTRALS_6;

    &::placeholder {
      color: $LIGHT_NEUTRALS_5;
    }
  }
  & &__input .ant-input-group {
    padding: 0 12px;
    @include flex(flex-start, center);
    & .ant-input-group-addon {
      width: initial;
      background-color: transparent;
      border: none;
      padding: 0;
      @include text($H13, 400, $LIGHT_NEUTRALS_6);
    }
    & .ant-input {
      border: none;
      width: initial;
      box-shadow: none;
    }
  }

  & &__label {
    @include text($H10, 400, $LIGHT_NEUTRALS_11);
    margin-bottom: 8px;
    @include flex(flex-start, center);
    white-space: nowrap;
    &._big {
      font-size: $H13;
    }
  }
  & &__label-required {
    @include text($H10, 400, $LIGHT_NEUTRALS_6);
    margin-left: 8px;
  }
  .anticon[tabindex] {
    color: $LIGHT_NEUTRALS_6;
  }
  &__icon {
    color: $LIGHT_NEUTRALS_6;
  }
  &__tooltip-icon {
    color: $LIGHT_NEUTRALS_6;
    margin-left: 6px;
  }
}

html.dark .#{$style} {
  &__input,
  &__input.ant-input-affix-wrapper {
    background-color: $DARK_NEUTRALS_11;
    border: 1px solid $DARK_NEUTRALS_10;
    color: $DARK_NEUTRALS_3;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $DARK_NEUTRALS_11 inset !important;
      -webkit-text-fill-color: $DARK_NEUTRALS_3;
    }
    &::placeholder {
      color: $DARK_NEUTRALS_6;
    }
    & .ant-input {
      background-color: $DARK_NEUTRALS_11;
      color: $DARK_NEUTRALS_3;

      &::placeholder {
        color: $DARK_NEUTRALS_6;
      }
    }
    &.ant-input[disabled] {
      background-color: $DARK_NEUTRALS_11;
      border-color: $DARK_NEUTRALS_10;
      &::placeholder {
        color: $DARK_NEUTRALS_9;
      }
    }
  }
  &__label {
    color: $DARK_NEUTRALS_1;
  }
  .anticon[tabindex] {
    color: $DARK_NEUTRALS_7;
  }
  &__icon {
    color: $DARK_NEUTRALS_7;
  }
}

.ant-form-item-has-error .#{$style} {
  .#{$style}__input {
    border-color: $LIGHT_RED_ACCENT;
  }
}

html.dark .ant-form-item-has-error .#{$style} {
  .#{$style}__input {
    background-color: $DARK_NEUTRALS_11;
    border-color: $DARK_RED_ACCENT;
  }
}
