@import 'src/theme/core';
$style: ProjectEngineHistory;
.#{$style} {
  &__table-head {
    padding: 12px 24px;
    cursor: pointer;
  }
  &__head-col {
    @include flex(flex-start, center);
    &._ta-right {
      justify-content: flex-end;
    }
  }
  &__head-icon {
    color: $LIGHT_NEUTRALS_6;
    font-size: 14px;
    margin-left: 4px;
    @include transition(color, transform);
    &._active {
      color: $LIGHT_BLUE_6;
    }
    &._rotate {
      transform: rotate(180deg);
    }
  }
  &__head-label {
    @include text($H10, 500, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
  }
  &__table-item {
    background: $LIGHT_NEUTRALS_0;
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 4px;
    padding: 12px 24px;
    margin-bottom: 8px;
  }
  &__table-col {
    &._ta-right {
      text-align: right;
    }
    &._with-dot {
      @include flex(flex-start, center);
    }
  }
  &__table-label {
    @include text($H12, 400, $LIGHT_NEUTRALS_10);
  }
  &__dropdown {
    @include flex(flex-start, flex-start, column);
  }
  &__dropdown-btn {
    background-color: transparent;
    border: none;
    outline: none;
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    cursor: pointer;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: $LIGHT_BLUE_6;
    }
  }
  &__lock {
    margin-right: 24px;
  }
  &__dropdown-trigger {
    color: $LIGHT_NEUTRALS_6;
  }
}
