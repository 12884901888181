@import 'src/theme/core';
.nav {
  @include flex(flex-start, center);
  background: $LIGHT_NEUTRALS_0;
  border: 1px solid $LIGHT_NEUTRALS_3;
  border-radius: 16px;
  height: 30px;
  width: 130px;
  overflow: hidden;
}
.link {
  border-right: 1px solid $LIGHT_NEUTRALS_3;
  flex: 1;
  @include flex(center, center);
  height: 100%;
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  &:last-of-type {
    border-right: none;
  }
  &.active {
    font-weight: 600;
    color: $LIGHT_NEUTRALS_0;
    background-color: $LIGHT_BLUE_6;
  }
}
