@import 'src/theme/core';

.title {
  @include text($H16, 600, $LIGHT_NEUTRALS_11);
  margin-bottom: 14px;
}
.row {
  margin-bottom: 14px;
}
.codemirrorWrapper {
  margin-bottom: 14px;
  padding: 0px 9px;
}
.switchText {
  @include text($H14, 600, $LIGHT_NEUTRALS_11);
  padding-left: 8px;
}
.errorTextBlcok {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 25px;
  top: 20px;
}
.errorText {
  @include text($H12, 400, $LIGHT_RED_ACCENT);
}
.boldText {
  @include text($H12, 600, $LIGHT_NEUTRALS_11);
  &.warning {
    color: $LIGHT_RED_ACCENT;
  }
}
.jsonViewer div {
  // font-family: ;
  font-size: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.copyRules {
  color: $LIGHT_NEUTRALS_7;
}
