@import 'src/theme/core';
$style: ProjectEngineEngineAddDropdown;
.#{$style} {
  @include flex(flex-start, center);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
  &._secondary {
    padding-left: 24px;
    .#{$style}__plus {
      color: $LIGHT_NEUTRALS_5;
    }
  }
  &._open {
    .#{$style}__plus {
      color: $LIGHT_BLUE_6;
    }
  }
  &__line {
    width: 2px;
    height: 20px;
    background-color: $LIGHT_NEUTRALS_5;
    border-radius: 1px;
    margin-right: 10px;
  }
  &__plus {
    color: $LIGHT_BLUE_6;
    margin-right: 2px;
    font-size: 18px;
    @include transition(color);
  }
  &__chevron {
    color: $LIGHT_NEUTRALS_6;
    @include transition(transform);
    font-size: 18px;
    &._active {
      transform: rotate(180deg);
    }
  }
  &__dropdown {
    @include flex(flex-start, flex-start, column);
  }
  &__dropdown-btn {
    background-color: transparent;
    border: none;
    outline: none;
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    cursor: pointer;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: $LIGHT_BLUE_6;
    }
  }
}
