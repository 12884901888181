@import 'src/theme/core';
.key {
  @include text($H14, 400, #3394dc);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.value {
  @include text($H14, 400, $LIGHT_NEUTRALS_7);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.number {
    color: $LIGHT-GREEN_3;
  }
}
.col {
  @include flex(items-start, center);
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 200px;
}
.colRight {
  width: 100%;
  max-width: 540px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item {
  position: relative;
  left: -20px;
  &:hover {
    .icon {
      visibility: visible;
    }
  }
}
.icon {
  visibility: hidden;
  position: absolute;
  left: 0px;
  top: 5px;
  color: $LIGHT_BLUE_8;
}
.tooltip {
  @include flex(flex-start, flex-start, column);
  margin: 0 -16px;
}
.label {
  span {
    font-weight: 700;
    color: $LIGHT_BLUE_8;
  }
}
.button {
  @include flex(flex-start, center);
  padding: 4px 16px;
  flex: 1 1 100%;
  width: 100%;
  &:hover {
    background: $LIGHT_BLUE_2;
  }
}
