@import 'src/theme/core';

.pieChartContainer {
  display: flex;
  flex-direction: row;
}

.wrapperChart {
  &.origin {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.grayed {
    opacity: 0.3;
    pointer-events: none;
    filter: grayscale(60%);
    transition: all 0.3s ease-in-out;
  }
}

.legendTitle {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
  white-space: break-spaces;
  overflow-wrap: normal;
  // letter-spacing: 1px;
  // text-wrap: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.legendContainer {
  width: 100%;
  padding-left: 15px;
  overflow: hidden;
  text-wrap: nowrap;
  white-space: nowrap;
  list-style-type: none;

  .legendDivider {
    position: absolute;
    background-color: $LIGHT_NEUTRALS_3;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
  }
}

.legend {
  font-size: 13px;
  font-weight: 500;
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
  list-style-type: none;

  &.hoveredItem {
    cursor: pointer;
    background-color: $LIGHT_BLUE_1;
  }
}

.titleName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleColumn {
  text-align: end;
  padding: 0 0 0 16px;
}
