@import 'src/theme/core';
$style: Notification;
.#{$style} {
  background-color: $LIGHT_NEUTRALS_0;
  box-shadow: 0px 4px 14px 2px rgba(22, 32, 39, 0.11);
  border-radius: 10px;
  padding: 16px 16px 16px;
  &._with-message {
    padding: 16px 16px 14px;
  }

  & .ant-notification-notice-icon {
    margin-left: 0;
    font-size: 16px;
    line-height: 16px;
  }

  & .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin: 0;
  }
  & .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin: 0 0 0 4px;
  }
  &__title {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    margin: 0 0 0 30px;
    line-height: 16px;
    &._with-message {
      @include text($H12, 500, $LIGHT_NEUTRALS_7);
      margin: 0 0 6px 24px;
    }
  }
  &__description {
    position: relative;
  }
  &__text {
    max-width: 80%;
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    line-height: 1.5;
    margin: 0;
  }
  &__date {
    position: absolute;
    right: 0;
    bottom: 0;
    @include text($H10, 400, $LIGHT_NEUTRALS_6);
  }
  &__status-icon-wrapper {
    background-color: $LIGHT-GREEN_3;
    @include size(18px);
    @include flex(center, center);
    border-radius: 2px;
    &._with-message {
      @include size(14px);
      position: relative;
      top: 1px;
      .#{$style}__status-icon {
        font-size: 10px;
      }
    }
    &._cross {
      background-color: $LIGHT_RED_ACCENT;
    }
    &._priority-hight {
      background-color: $LIGHT_ORANGE_ACCENT;
    }
    &._info {
      background-color: $LIGHT_BLUE_6;
    }
  }
  & &__status-icon {
    font-size: 14px;
    color: $LIGHT_NEUTRALS_0;
  }
  & &__close-icon {
    font-size: 16px;
    color: $LIGHT_NEUTRALS_7;
  }
  & .ant-notification-notice-close {
    top: 14px;
    right: 12px;
  }
}

html.dark .#{$style} {
  background-color: $DARK_NEUTRALS_12;
  border: 1px solid $DARK_NEUTRALS_10;
  box-shadow: 0px 6px 14px 2px rgba(35, 35, 35, 0.6);
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    color: $DARK_NEUTRALS_1;
  }
  &__text {
    color: $DARK_NEUTRALS_1;
  }
  &__date {
    color: $DARK_NEUTRALS_7;
  }
  &__close-icon {
    color: $DARK_NEUTRALS_1;
  }
}
