@import 'src/theme/core';

.tag {
  @include flex(flex-start, center);
  height: 24px;
  // background: $LIGHT_NEUTRALS_3;
  border-radius: 4px;
  padding: 0 6px;

  // .tagIcon {
  //   display: none;
  // }

  // &:hover {
  //   border: 1px solid $LIGHT_NEUTRALS_7;
  //   box-sizing: border-box;
  //   padding: 0 8px 0 11px;

  //   .tagIcon {
  //     display: block;
  //   }
  // }
}

.tagInput {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  // margin-right: 8px;
  background: transparent;
  border: none;
  outline: none;
  display: none;

  &.active {
    display: initial;
  }
}

// .tagIcon {
//   color: $LIGHT_NEUTRALS_7;
//   font-size: 11px;
//   cursor: pointer;

//   &:hover {
//     color: $LIGHT_NEUTRALS_11;
//   }
// }

.label {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  // margin-right: 8px;
  background: transparent;
  border: none;
  outline: none;
  color: #c2129d;
  font-weight: 600;
}

.errorIcon {
  // font-size: 17px;
  color: red
}
