@import 'src/theme/core';

$style: Select;

$size: 20px;

.#{$style} {
  @include flex-col;

  &__image {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    position: relative;
    bottom: 1px;
  }

  &._big {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 48px;
    }
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
    .#{$style}__label {
      @include text($H13, 400, $LIGHT_NEUTRALS_11);
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
  }

  &__select {
    &.ant-select:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
      border-color: $LIGHT_NEUTRALS_2 !important;
      background-color: #fafbfc !important;
      & .ant-select-selection-item {
        color: $LIGHT_NEUTRALS_6 !important;
      }
    }
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $LIGHT_NEUTRALS_1;
      border: 1px solid $LIGHT_NEUTRALS_4;
      border-radius: 4px;
    }
    &.ant-select:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
      background-color: $LIGHT_NEUTRALS_2;
      border: 1px solid $LIGHT_NEUTRALS_4;
    }
    &.ant-select-open .#{$style}__arrow {
      transform: rotate(180deg);
    }
    .ant-select-selection-placeholder {
      @include text($H13, 400, $LIGHT_NEUTRALS_6);
    }
    &.ant-select:not(.ant-select-customize-input).ant-select-disabled
      .ant-select-selection-placeholder {
      color: $LIGHT_NEUTRALS_6;
    }
    .ant-select-selection-item {
      @include text($H13, 400, $LIGHT_NEUTRALS_11);
    }
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: $LIGHT_BLUE_6;
      box-shadow: 0px 0px 1px 2px rgba(26, 89, 217, 0.1);
    }
  }
  &__arrow {
    color: $LIGHT_NEUTRALS_6;
    position: relative;
    @include transition;
    font-size: 16px;
  }

  & &__label {
    @include text($H10, 400, $LIGHT_NEUTRALS_11);
    margin-bottom: 8px;
    white-space: nowrap;
  }
  &__select-dropdown {
    box-shadow: 0px 4px 14px 2px rgba(22, 32, 39, 0.11);
    border-radius: 4px;
    .ant-select-item {
      color: $LIGHT_NEUTRALS_11;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: transparent;
      color: $LIGHT_NEUTRALS_11;
      font-weight: 400;
      .#{$style}__check {
        display: inline-flex;
        align-items: center;
        color: $LIGHT_BLUE_3_ORIGINAL;
        font-size: 14px;
        position: absolute;
        right: 0px;
        top: 4px;
      }
    }
    .ant-select-item-option-content {
      position: relative;
      padding-left: 0px;
    }
  }
  &__check {
    display: none;
  }
}

html.dark .#{$style} {
  &__select {
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: $DARK_NEUTRALS_11;
      border-color: $DARK_NEUTRALS_10;
    }
    &.ant-select:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
      background-color: $DARK_NEUTRALS_11;
      border-color: $DARK_NEUTRALS_10;
    }
    .ant-select-selection-placeholder {
      color: $DARK_NEUTRALS_6;
    }
    &.ant-select:not(.ant-select-customize-input).ant-select-disabled
      .ant-select-selection-placeholder {
      color: $DARK_NEUTRALS_9;
    }
  }
  .ant-select-selection-item {
    color: $DARK_NEUTRALS_3;
  }
  &__select-dropdown {
    background-color: $DARK_NEUTRALS_11;
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: $DARK_NEUTRALS_3;
      background-color: $LIGHT_NEUTRALS_9;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      color: $DARK_NEUTRALS_3;
      background-color: $LIGHT_NEUTRALS_9;
    }
    .ant-select-item {
      color: $LIGHT_NEUTRALS_5;
    }
  }
  &__arrow {
    color: $DARK_NEUTRALS_1;
    pointer-events: none;
  }
  &__label {
    color: $DARK_NEUTRALS_1;
  }
}
