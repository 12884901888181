@import 'src/theme/core';
$style: ProjectOriginCnames;
.#{$style} {
  &__title {
    @include text($H16, 600, $LIGHT_NEUTRALS_11);
    margin: 0;
  }
  &__head {
    padding: 12px 24px 16px;
  }
  &__table-head {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 12px 24px;
  }
  &__head-col {
    &._ta-right {
      text-align: right;
    }
  }
  &__head-label {
    @include text($H10, 500, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
  }
  &__table-item {
    border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding: 14px 24px;
  }
  &__table-col {
    &._ta-right {
      text-align: right;
    }
  }
  &__table-label {
    @include text($H12, 400, $LIGHT_NEUTRALS_10);
  }
  &__badge {
    margin-left: 12px;
  }
  &__link-icon {
    color: $LIGHT_NEUTRALS_6;
    margin-left: 6px;
    @include transition(color);
    cursor: pointer;
    &:hover {
      color: $LIGHT_NEUTRALS_7;
    }
    &._margin-less {
      margin-left: 0;
    }
  }
}
