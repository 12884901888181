@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
