@import 'src/theme/core';
$style: App;

.#{$style} {
  min-height: 100vh;
  &__notify {
    width: 100%;
    position: fixed;
    top: 100px;
    @include flex(center, center);
  }
  &__notify-notice {
    width: 460px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__notify-success {
    padding: 12px 16px;
    @include text($H12, 400, $LIGHT-GREEN_3);
    @include flex(flex-start, center);
    background-color: $LIGHT-GREEN_1;
    border-radius: 10px;
    .anticon-check-circle {
      font-size: 20px;
      margin-right: 12px;
    }
  }
  &__notify-error {
    padding: 12px 16px;
    @include text($H12, 400, $LIGHT_RED_ACCENT);
    @include flex(flex-start, center);
    background-color: $LIGHT_RED_1;
    border-radius: 10px;
    .anticon-close-circle {
      font-size: 20px;
      margin-right: 12px;
    }
  }
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

html.dark .#{$style} {
  &__notify-success {
    color: $DARK_GREEN_2;
    background-color: $DARK_GREEN_4;
  }
  &__notify-error {
    color: $DARK_RED_4;
    background-color: $DARK_RED_ACCENT;
  }
}

.TooltipCustom {
  padding: 4px;
  @include flex(flex-start, flex-start, column);
  &__row {
    @include flex(space-between, center);
    width: 100%;
  }
  &__label {
    @include flex(flex-start, center);
    // @include text();
  }
  &__dot {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #1a59d9;
    margin-right: 8px;
  }
  &__value {
    @include text($H12, 500, $LIGHT_NEUTRALS_9);
  }
  &__name {
    @include text($H14, 700, $LIGHT_NEUTRALS_7);
    display: inline-block;
    margin-bottom: 12px;
  }
  &__col {
    @include flex(flex-start, center);
  }
}
