@import 'src/theme/core';

.tag {
  @include flex(flex-start, center);
  height: 24px;
  background: $LIGHT_NEUTRALS_3;
  border-radius: 4px;
  padding: 0 8px 0 12px;
  width: 100%;

  .tagIcon {
    display: none;
  }

  &:hover {
    border: 1px solid $LIGHT_NEUTRALS_7;
    box-sizing: border-box;
    padding: 0 8px 0 11px;

    .tagIcon {
      display: block;
    }

    .label {
      margin-right: 0px;
    }

    .tagInput {
      margin-right: 0px;
    }
  }
}

.tagInput {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  margin-right: 12px;
  background: transparent;
  border: none;
  outline: none;
  display: none;

  &.active {
    display: initial;
  }
}

.tagIcon {
  color: $LIGHT_NEUTRALS_7;
  font-size: 11px;
  right: -5px;
  position: relative;
  cursor: pointer;

  &:hover {
    color: $LIGHT_NEUTRALS_11;
  }
}

.label {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  margin-right: 12px;
  background: transparent;
  border: none;
  outline: none;
  text-wrap: nowrap;
  white-space: nowrap;
}

.tagColon {
  color: #c2129d;
  font-weight: 600;
}

.tagParam {
  color: $DARK_NEUTRALS_7;
}

.tagValue {}
