@import 'src/theme/core';

.copyIcon {
    svg {
        fill: $LIGHT_NEUTRALS_3;
        transition: all 0.2 ease;
    }

    &:hover {
        svg {
            fill: $LIGHT_NEUTRALS_1;
            transition: all 0.2 ease;
        }
    }
}

.dotsIcon {
    font-size: 17px;

    svg {
        fill: $LIGHT_NEUTRALS_10;
        transition: all 0.2 ease;
    }

    &:hover {
        transform: scale(1.08);

        svg {
            fill: $LIGHT_NEUTRALS_7;
            transition: all 0.2 ease;
        }
    }

}

.dotsIconDisabled {
    font-size: 17px;

    svg {
        fill: $LIGHT_NEUTRALS_5;
        cursor: default !important;
        pointer-events: none;
    }

    &:hover {
        cursor: default;
    }
}

.diffIcon {
    margin-left: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2 ease;

    svg {
        fill: $LIGHT_NEUTRALS_6;
        transition: all 0.2 ease;
    }

    &:hover {
        svg {
            fill: $LIGHT_NEUTRALS_10;
            transition: all 0.2 ease;
        }
    }
}

.moreDetails {
    .ant-tooltip-inner {
        padding: 4px 0;
    }

    .deploymentsDetailsBtns {
        display: flex;
        flex-direction: column;

        .additionalBtn {
            padding: 7px 10px;
            justify-content: start;


            .deleteBtn-color {
                color: $LIGHT_RED_ACCENT;
                font-size: 14px;
            }

            .redeployBtn-color {
                font-size: 14px;
                color: $LIGHT_BLUE_5;
            }

            .detailsBtn-color {
                color: $DARK_NEUTRALS_11;
                font-size: 14px;
            }
        }

        .additionalBtn:hover {
            background-color: $LIGHT_BLUE_1;
        }

        .additionalBtn:disabled {
            opacity: 0.3;
            background-color: #fff;
        }
    }
}

.detailsHeaderDots {
    .ant-tooltip-inner {
        padding: 4px 0;
    }

    .additionalBtn {
        padding: 7px 0px;

        .deleteBtn-color {
            color: $LIGHT_RED_ACCENT;
            font-size: 14px;
        }
    }

    .additionalBtn:hover {
        background-color: $LIGHT_BLUE_1;
    }

    .additionalBtn:disabled {
        opacity: 0.3;
        background-color: #fff;
    }
}

.deploymentsTable {
    position: relative;

    // bottom: 20px;
    // margin-bottom: 10px;
    table {
        border-collapse: collapse;
    }

    .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody {
        border: 1px solid $LIGHT_NEUTRALS_3;
    }

    .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody .ant-table-row-selected .ant-table-cell {
        background-color: $LIGHT_BLUE_1;
    }

    .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
        background-color: #fff;
        font-size: 0.625rem;
        font-weight: 600;
        color: #90a0b7;
        text-transform: uppercase;
        padding: 16px 16px;
    }

    .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody .ant-table-cell {
        padding: 12px 16px;
    }

    ::before {
        width: 0px !important
    }
}
