@import 'src/theme/core';

.title {
  @include text($H14, 600, $LIGHT_NEUTRALS_11);
  margin: 0;
}

.head {
  padding: 16px 0px 0px;
}

.tableHead {
  border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  padding: 12px 24px;
}

.headLabel {
  @include text($H10, 500, $LIGHT_NEUTRALS_6);
  text-transform: uppercase;
}

.item {
  border: 1px solid $LIGHT_NEUTRALS_3;
  padding: 14px 16px;
  height: 52px;

  &.last {
    border-bottom: none;
  }
}

.col {
  &.rightAlign {
    text-align: right;
  }
}

.tableLabel {
  @include text($H14, 600, $LIGHT_NEUTRALS_7);
}

.tableLabelHostnames {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.linkIcon {
  color: $LIGHT_NEUTRALS_6;
  margin-left: 6px;
  @include transition(color);
  cursor: pointer;

  &:hover {
    color: $LIGHT_NEUTRALS_7;
  }

  &.ml {
    margin-left: 0;
  }
}

.accessControlDetailsBtns {
  padding: 16px;
}

.additionalBtn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.dotsIcon {
  color: gray;
  transition: all 0.16s;

  &:hover {
    color: $LIGHT_NEUTRALS_10;
  }
}

.footerButton {
  width: 100% !important;
}
