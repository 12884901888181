@import 'src/theme/core';
.item {
  background: $LIGHT_NEUTRALS_0;
}
.head {
  // height: 52px;
  cursor: pointer;
}
.icon {
  font-size: 24px;
  color: $LIGHT_NEUTRALS_6;
  transform: rotate(-90deg);
  position: relative;
  top: 2px;
  &.active {
    transform: rotate(0deg);
  }
}
.text {
  @include text($H14, 500, $LIGHT_NEUTRALS_10);
}
.subtext {
  @include text($H12, 400, $LIGHT_NEUTRALS_7);
}
