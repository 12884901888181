@import 'src/theme/core';
$style: ProjectChartsBubble;
.#{$style} {
  @include flex(center, center);
  @include size(14px, true);
  border: 1px solid $LIGHT-GREEN_3;
  background-color: $LIGHT-GREEN_1;
  @include text($H9, 400, $LIGHT-GREEN_3);
  &.level-3 {
    @include size(20px, true);
  }
  &.level-5 {
    @include size(32px, true);
  }
  &.level-8 {
    @include size(48px, true);
  }
  &.level-10 {
    @include size(60px, true);
  }
}
