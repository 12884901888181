@import 'src/theme/core';
.ldsFacebook {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 8px;
    background: $LIGHT_BLUE_3_ORIGINAL;
    animation: ldsFacebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    left: 0px;
    animation-delay: -0.24s;
  }
  div:nth-child(2) {
    left: 12px;
    animation-delay: -0.12s;
  }
  div:nth-child(3) {
    left: 24px;
    animation-delay: 0;
  }
}

@keyframes ldsFacebook {
  0% {
    top: 4px;
    height: 30px;
  }
  50%,
  100% {
    top: 10px;
    height: 20px;
  }
}
