@import 'src/theme/core';

.ProjectOriginPciDetails {
    .wrapper {
        display: flex;
    }

    .iconPlanet {
        position: relative;
        top: 1px;
        padding-right: 5px
    }

    h1 {
        font-size: 13px;
        font-weight: 400;
    }

    .supportText {
        color: gray;
        opacity: 0.9;
        font-size: 12px;
    }
}
