@import 'src/theme/core';

.mainLayout {
  min-height: 100vh;
  min-width: 1210px;
  background: $LIGHT_NEUTRALS_3;

  &.active {
    color: $DARK_NEUTRALS_7;
    background-color: $LIGHT_BLUE_2;
    // transition: all 0.2s ease;
  }
}

.pageName {
  @include text($H18, 600, $LIGHT_NEUTRALS_10);
  line-height: 22px;
}

.routeIconGold {
  color: $DARK_YELLOW_2;
  font-size: 16px;
  padding: 6px 8px;
}

.routeIcon {
  color: $LIGHT_NEUTRALS_7;
  font-size: 16px;
  padding: 6px 8px;
}

.bottomMenu {
  margin-bottom: 16px
}

.leftBar {
  background: $LIGHT_LEFTBAR_PROPERTIES;
  @include flex(space-between, flex-start, column);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 48px;
  // padding: 9px 0 16px 8px;
  z-index: 3;
  transition: all 0.2s;
  overflow: hidden;
}

.superAdminHeader {
  width: calc(100% - 56px);
  height: 56px;
  background: $LIGHT_NEUTRALS_3;
  padding: 0 16px;
  position: fixed;
  left: 48px;
  z-index: 100;
  box-shadow: 0px 8px 32px rgba(51, 65, 100, 0.14);
}

.superAdminHeaderBox {
  line-height: 56px
}

.superAdminExitBtn {
  margin-left: 16px;
  background-color: $LIGHT_ORANGE_ACCENT !important;
  border-color: $LIGHT_ORANGE_4 !important;
}

.logo {
  padding: 9px 8px;
  font-size: 24px;
  z-index: 0 !important;
  transition: all 0.2s ease;
}


.logoFull {
  svg {
    width: 105px;
  }
}

.logoSmall {
  position: relative;
  left: 4px;
}

.content {
  // padding: 16px;
  margin-left: 224px;
  @include flex(stretch, stretch, column);
  overflow: hidden;
  transition: all 0.2s ease;
  background: $LIGHT_NEUTRALS_0;
  // box-shadow: 0px 8px 32px rgba(51, 65, 100, 0.14);
  // border-radius: 10px 0px 0px 10px;
  z-index: 4;

  &.home {
    margin-left: 48px;
    border-radius: 0;
    box-shadow: none;
    background-color: $LIGHT_NEUTRALS_0;
  }

  &.collapsed {
    margin-left: 100px;
  }
}

.projectName {
  @include text($H13, 400, $LIGHT_NEUTRALS_9);
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 25px;
}

.project {
  margin: 6px 0px 5px 8px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.collapsed {
    width: auto;

    .arrow {
      opacity: 0;
    }
  }
}

// .nav {
//   padding: 5px 0 16px 0;
//   @include transition();

//   &.collapsed {
//     padding: 5px 0 16px 0px;
//   }
// }

// .nav {
//   padding: 5px 0 16px 0;
//   @include transition();

//   &.collapsed {
//     padding: 5px 0 16px 0px;
//   }
// }

// .route {
//   @include flex(flex-start, center);
//   gap: 0px;
//   @include text($H14, 600, $DARK_NEUTRALS_7);
//   width: 100%;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   height: 26px;
//   border-radius: 4px;
//   // transition: all 0.2s ease;
//   font-weight: 400;

//   &:hover {
//     color: $DARK_NEUTRALS_7;
//     background-color: $LIGHT_BLUE_2;
//     // border-bottom: 1px solid white;
//     // transition: all 0.2s ease;
//   }

//   &.activeLeftMenu {
//     color: $DARK_BLUE_ORIGINAL;
//   }

//   &.active {
//     color: $DARK_NEUTRALS_7;
//     background-color: $LIGHT_BLUE_2;
//     // transition: all 0.2s ease;
//   }

//   .routeIcon {
//     font-size: 16px;
//     padding: 6px 8px;
//   }

//   .routeIconLeftMenu {
//     font-size: 16px;
//     padding: 5px 6px;
//   }
// }

.routeLeftBar {
  @include flex(flex-start, center);
  gap: 0px;
  @include text($H14, 600, $DARK_NEUTRALS_7);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 28px;
  border: 1px solid $LIGHT_LEFTBAR_PROPERTIES;
  border-radius: 4px;
  // box-sizing: content-box;
  // transition: all 0.2s ease;

  &:hover {
    color: $LIGHT_NEUTRALS_6;
    // transition: all 0.2s ease;
    border: 1px solid;
    border-radius: 4px;
  }

  &.activeLeftMenu {
    color: $DARK_BLUE_ORIGINAL;
    // transition: all 0.2s ease;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
  }

  &.active {
    border: 1px solid;
    color: $LIGHT_BLUE_6;
    background-color: $LIGHT_BLUE_2;
    // transition: all 0.2s ease;
  }

  .routeIcon {
    font-size: 16px;
    padding: 6px 8px;
  }

  .routeIconLeftMenu {
    font-size: 16px;
    padding: 5px 7px;
  }
}

.routeNav {
  @include flex(flex-start, center);
  gap: 0px;
  @include text($H14, 600, $DARK_NEUTRALS_7);
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 28px;
  // transition: all 0.2s ease;
  border-radius: 4px;
  font-weight: 400;

  &:hover {
    color: $DARK_NEUTRALS_7;
    background-color: $LIGHT_NEUTRALS_3;
    // transition: all 0.2s ease;
  }

  &.activeLeftMenu {
    color: $DARK_BLUE_ORIGINAL;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
  }

  &.active {
    color: $DARK_NEUTRALS_7;
    background-color: $LIGHT_BLUE_2;
    // transition: all 0.2s ease;
  }

  .routeIcon {
    color: $DARK_NEUTRALS_7;
    font-size: 16px;
    padding: 6px 8px;
  }

  .routeIconLeftMenu {
    color: $DARK_NEUTRALS_7;
    font-size: 16px;
    padding: 5px 7px;
  }
}

.triggerButton {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.subrouts {
  width: 120px;
}

.navMenuText {
  // @include text($H12, 500, $DARK_NEUTRALS_7);
  // @include flex(center, center);
  padding-left: 8px;
}

.dropdownText {
  @include text($H12, 500, $DARK_NEUTRALS_7);
  @include flex(center, center);
}

.trigger {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  @include flex(flex-start, center);
}

.textWrapper {
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
