@import 'src/theme/core';
$style: UserSettingsBillingReports;

.#{$style} {
  &__title {
    @include text($H18, 600, $LIGHT_NEUTRALS_11);
    margin-bottom: 16px;
    @include flex(flex-start, center);
    gap: 10px;
  }
  &__subtitle {
    @include text($H12, 400, $LIGHT_NEUTRALS_7);
    display: inline-block;
    margin: 0;
    margin-top: 4px;
  }
  &__table-head-item {
    @include flex(flex-start);
    @include text($H10, 500, $LIGHT_NEUTRALS_6);
    text-transform: uppercase;
    &._start {
      @include flex(flex-start);
      padding-left: 12px;
    }
    ._first {
      padding-left: 24px;
    }
    ._last {
      padding-right: 24px;
    }
  }
  &__table-head {
    padding-bottom: 12px;
    // border-bottom: 1px solid $LIGHT_NEUTRALS_3;
  }
  &__col {
    @include flex(flex-end, center);
    border-top: 1px solid $LIGHT_NEUTRALS_3;
    // border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    padding-bottom: 14px;
    &._primary {
      padding-bottom: 7px;
      border-bottom-color: transparent;
    }
    &._last {
      border-bottom-color: transparent;
    }
    &._first {
      border-top-color: transparent;
    }
  }
  &__controls {
    position: absolute;
    top: 16px;
    right: 32px;
    @include flex(flex-start, center);
    gap: 24px;
  }
  &__dropdown-trigger {
    background-color: transparent;
    @include text($H12, 600, $LIGHT_BLUE_6);
    border: none;
    cursor: pointer;
    @include flex(center, center);
    gap: 4px;
  }
  &__dropdown-icon {
    color: $LIGHT_BLUE_6;
    font-size: 16px;
    @include transition(transform);
    &._active {
      transform: rotate(180deg);
    }
  }
  &__calendar {
    & .ant-picker-input {
      & > input {
        @include text($H14, 600, $LIGHT_BLUE_6);
        padding: 0;
        width: 66px;
      }
    }
    & .ant-picker-clear {
      display: none!important;
    }
  }
  &__separator {
    display: inline-block;
    width: 12px;
    height: 1px;
    background-color: $LIGHT_BLUE_6;
    @include flex(center, center);
  }
  // &__table-item {
  //   padding: 0 14px 0 0;
  //   &._primary {
  //     background-color: $LIGHT_NEUTRALS_1;
  //     margin-bottom: 4px;
  //   }
  //   &._first {
  //     border-top-color: transparent;
  //   }
  // }
  // &__table-item-first {
  //   padding-bottom: 14px;
  //   padding-top: 12px;
  //   cursor: pointer;
  //   border-top: 1px solid $LIGHT_NEUTRALS_3;
  //   @include flex(flex-start, center);
  //   &._primary {
  //     padding-left: 20px;
  //     padding-bottom: 7px;
  //     padding-top: 7px;
  //   }
  //   &._first {
  //     border-top-color: transparent;
  //   }
  //   &._last {
  //     border-bottom-color: transparent;
  //   }
  // }
  // &__upload-icon {
  //   font-size: 16px;
  // }
  // &__expand-icon {
  //   font-size: 22px;
  //   color: $LIGHT_NEUTRALS_6;
  //   margin-right: 16px;
  //   transform: rotate(-90deg);
  //   &._active {
  //     transform: rotate(0deg);
  //   }
  // }
  // &__text {
  //   @include text($H12, 400, $LIGHT_NEUTRALS_10);
  // }
  // &__empty-state {
  //   height: calc(100vh - 200px);
  // }
  // &__empty-state-title {
  //   @include text($H32, 600, $LIGHT_NEUTRALS_11);
  // }
  // &__empty-state-ilustration {
  //   height: 100%;
  //   width: 100%;
  // }
}
