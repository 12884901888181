@import 'src/theme/core';

.ant-picker-range-wrapper {
    border: 1px solid $LIGHT_NEUTRALS_3;
    border-radius: 3px;
}

.ant-picker-panel-has-range {
    border: none;
}

.ant-picker-panel-container {
    @include flex(stretch, stretch);

    & .ant-picker-footer {
        border-left: 1px solid $LIGHT_NEUTRALS_3;
        border-bottom: none;
        min-width: 160px;
    }

    .ant-picker-footer-extra {
        padding: 8px 12px 0;
        line-height: 29px;
        border: none;
    }

    .ant-picker-datetime-panel {
        border-bottom: 1px solid $LIGHT_NEUTRALS_3;
    }
}

.ant-picker-range {
    .ant-picker-range-separator {
        padding: 0px;
        color: $LIGHT_BLUE_6;
    }
    .ant-picker-active-bar {
        background: $LIGHT_BLUE_6;
    }
    input {
        text-align: center;
        font-size: 12px;
        color: $LIGHT_BLUE_6;
    }
}

.ant-picker-focused {
    .ant-picker-active-bar {
        margin-left: 0px;
    }
}
